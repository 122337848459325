import { InsuranceCategory, InsuredType, RegimeEnum } from '../lead/types';
import { DocumentModel } from '../document/types';
import { BankAccountModel } from '../bank-account/types';
import { AccountMoveModel } from '../accounting/types';

export const contractFeatureKey = 'contracts';
export type ContractModel = {
  id: number;
  leadId: number;
  category: InsuranceCategory;
  categoryLoc: string;
  categoryColor: string;
  productCategoryId?: number;
  productCategoryName: string;
  productCategoryProductCategoryEnumKey: number;
  uniqueId: string;
  contactGender: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  contactZipCode: string;
  contactCity: string;
  contactAddress: string;
  contactAddress2: string;
  contactCountryName: string;
  creationDate: Date;
  gender: string;
  monthlyFee: number;
  statusId: ContractStatus;
  statusInt: number;
  statusLoc: string;
  statusColor: string;
  productLineId: number;
  productId: number;
  productKey: string;
  productLineKey: string;
  companyKey: string;
  startDate: Date;
  endDate: Date;
  name: string;
  subscriptionDate: Date | undefined;
  documentsReceivedDate: Date | undefined;
  documentsSentDate: Date | undefined;
  regularizationDate: Date | undefined;
  expiryMonth: number | undefined;
  expiryDay: number | undefined;
  terminationDate: Date | undefined;
  terminationReasonId: number | undefined;
  applicationFees: number | undefined;
  monthlyCost: number | undefined;
  annualFee: number | undefined;
  commissionRate: number | undefined;
  taxRate: number | undefined;
  validationDate: Date | undefined;
  approverId: number | undefined;
  contractManagerId: number;
  contactId: number;
  paymentBankAccountId: number;
  paymentBankAccount: BankAccountModel | undefined;
  refundingBankAccountId: number;
  refundingBankAccount: BankAccountModel | undefined;
  paymentSplittingId: number;
  paymentSplittingMode: string;
  paymentSplittingDay: string;
  campaignId: number;
  campaignName: string | undefined;
  medium: string;
  source: string;
  salesTeamName: string;
  salesTeamId: number;
  salesPersonContactName: string;
  salesPersonId: number;
  contactName: string;
  terminationReasonName: string;
  // paymentBankAccountHolderName: string;
  // paymentBankAccountIban: string;
  // paymentBankAccountBic: string;
  // paymentBankAccountBankId: number | undefined;
  // paymentBankAccountBankBankName: string;
  // refundingBankAccountHolderName: string;
  // refundingBankAccountIban: string;
  // refundingBankAccountBic: string;
  // refundingBankAccountBankId: number | undefined;
  // refundingBankAccountBankBankName: string;
  approverContactName: string;
  contractManagerContactName: string;
  documents: DocumentModel[];
  clientAccountMoves: AccountMoveModel[];
  companyAccountMoves: AccountMoveModel[];
  sharedSalesPersonId: number;
  sharedSalesPersonContactName: string;
  sharedCommissionRate: number;
  productProductLineId: number;
  productProductLineName: string;
  productProductLineInsuranceCompanyId: number;
  productProductLineInsuranceCompanyContactId: number;
  productProductLineInsuranceCompanyContactName: string;
  productName: string;
};

export type HealthInsuranceContractDetailModel = {
  id: number;
  healthInsuranceContractId: number;
  personId: number;
  insuredType: string;
  insuredTypeLoc: string;
  parentId: number | null;
  healthInsuranceContractInsuranceContractContractId: number;
  personFirstName: string;
  personLastName: string;
  personBirthDate: Date;
  personGender: string;
  personGenderLoc: string;
  personGenderInt: number;
  personMaritalStatusLoc: string;
  personMaritalStatus: string;
  personSocialSecurityNumber: string;
  personCodeCpam: string;
  personSocialSecurityKey: string;
  personSocialSecurityKeyLog: string;
};

export type CarInsuranceContractModel = {
  driverGender: string;
  driverFirstName: string;
  driverLastName: string;
  driverBirthdate: Date;
  driverZipCode: string;
  driverCity: string;
  driverAddress: string;
  driverMaritalStatus: string;
  driverMaritalStatusLoc: string;
  licenseDate: Date;
  isDriverVehiculeRegistrationHolder: boolean;
  vehiculeRegistrationHolder: string;
  vehiculeRegistrationHolderNameLastName: string;
  vehiculeRegistrationHolderNameFirstName: string;
  vehiculeRegistrationHolderCompanyName: string;
  vehiculeRegistrationHolderBirthdate: Date;
  yearsInsured: string;
  bonusMalus: string;
  monthInsuranceInterruption: string;
  insuranceTermination: string;
  licenseAnnulation: string;
  noLicenseConviction: string;
  licenseCancel: string;
  licenseCancelMotive: string;
  licenseCancelDate: Date;
  licenseSuspension: string;
  licenseSuspensionMotive: string;
  licenseSuspensionDate: Date;
  longestSuspension: string;
  alcoholDrugControl: string;
  nbCarDisaster: string;
  licensePlateNumber: string;
  brand: string;
  model: string;
  exactModel: string;
  carBody: string;
  carEnergie: string;
  carPower: string;
  vehiculeUsage: string;
  vehiculeUsageLoc: string;
  vehiculeUsageProSupp: string;
  vehiculeUsageProSuppLoc: string;
  parkingType: string;
  parkingTypeLoc: string;
  dateOfCirculation: Date;
  contractStartDate: Date;
  insuranceCoverage: string;
  currentInsuranceCompany: string;
  driverBodyCoverage: boolean;
  replacementVehicleCoverage: boolean;
  driverPropertyCoverage: boolean;
  brandNewCoverage: boolean;
  moneyLossCoverage: boolean;
  insuranceContractContractId: number;
  carDisasters: CarInsuranceContractDisasterDetailModel[];
};

export type CarInsuranceContractDisasterDetailModel = {
  carDisasterDate: Date;
  carDisasterResponsiblePerson: string;
  carDisasterReason: string;
};

export type HouseInsuranceContractModel = {
  address: string;
  zipCode: string;
  city: string;
  maritalStatus: string;
  profession: string;
  housingType: string;
  occupancyRole: string;
  nbOfRooms: number;
  area: number;
  constructionType: string;
  roofType: string;
  amountToInsure: string;
  flatLevel: string;
  residencyType: string;
  fireCoverage: boolean;
  waterCoverage: boolean;
  theftCoverage: boolean;
  glassBreakCoverage: boolean;
  publicLiabilityCoverage: boolean;
  feeCancellationCoverage: boolean;
  electricalDamageCoverage: boolean;
  poolCoverage: boolean;
  dogCoverage: boolean;
  currentInsuranceCompany: string;
};

export type ContractStatusFlow = {
  label: string;
  status: ContractStatus;
  statusId: number;
  statusColor: string;
};

export enum ContractStatus {
  Draft = 1,
  Confirmed = 2,
  Validated = 3,
  Open = 4,
  Pending = 5,
  Closed = 6,
  Cancelled = 7,
}

// export type ContractStatus =
//   | 'Draft'
//   | 'ContractRequest'
//   | 'WaitingValidation'
//   | 'WaitingRegularization'
//   | 'Open'
//   | 'Pending'
//   | 'Closed'
//   | 'Cancelled';

export type CreateContractFormModel = {
  contractModel: ContractModel;
  contactFormModel: ContactFormModel;
};

// export type ContractFormModel = {
//   id?: number;
//   category: InsuranceCategory;
//   categoryLoc: string;
//   salesTeamId: number;
//   salesPersonId: number;
//   campaignId: number;
//   medium: string;
//   source: string;
//   startDate: Date;
// };

export type ContactFormModel = {
  // id?: number;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
};

export type ContractHealthFormModel = {
  contractModel: ContractModel;
  healthInsuranceContractDetailFormModels: HealthInsuranceContractDetailFormModel[];
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};
// export type ContractFormModel = {
//   id?: number;
//   leadId: number;
//   category: InsuranceCategory;
//   categoryLoc: string | null;
//   categoryColor?: string;
//   productCategoryId?: number;
//   productCategoryName: string;
//   salesTeamId: number | undefined | null;
//   salesPersonId: number | undefined | null;
//   campaignId: number | undefined | null;
//   medium: string;
//   source: string;
//   monthlyFee: number;
//   creationDate: Date;
//   companyKey: string;
//   productLineKey: string;
//   productKey: string;
//   statusId: ContractStatus;
//   productLineId: number | undefined | null;
//   productId: number | undefined | null;
//   paymentSplittingId: number | undefined | null;
//   startDate: Date;
//   endDate: Date | undefined | null;
//   name: string;
//   subscriptionDate: Date | undefined;
//   documentsReceivedDate: Date | undefined;
//   documentsSentDate: Date | undefined;
//   regularizationDate: Date | undefined;
//   expiryMonth: number | undefined;
//   expiryDay: number | undefined;
//   terminationDate: Date | undefined;
//   terminationReasonId: number | undefined;
//   applicationFees: number | undefined;
//   monthlyCost: number | undefined;
//   commissionRate: number | undefined;
//   taxRate: number | undefined;
//   validationDate: Date | undefined;
//   approverId: number | undefined;
//   contractManagerId: number;
//   contactId: number;
// };

export type HealthInsuranceContractDetailFormModel = {
  insuredType: InsuredType;
  firstName: string;
  lastName: string;
  birthName: string;
  birthDate: Date;
  gender: number;
  maritalStatus: number;
  socialSecurityNumber: string;
  socialSecurityKey: RegimeEnum;
  codeCpam: string;
  affiliation: ChildAffiliation;
};

export type BankAccountAddModel = {
  iban: string;
  bic: string;
  holderName: string;
  active: boolean;
};

export type ContractAddBaseModel = {
  contractModel: ContractModel;
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};
export type ContractUpdateBaseModel = {
  contractModel: ContractModel;
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

export type ContractHouseFormModel = {
  contractModel: ContractModel;
  houseInsuranceContractFormModel: HouseInsuranceContractFormModel;
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

export type HouseInsuranceContractFormModel = {
  address: string;
  zipCode: string;
  city: string;
  maritalStatus: string;
  profession: string;
  housingType: string;
  occupancyRole: string;
  nbOfRooms: number;
  area: number;
  constructionType: string;
  roofType: string;
  amountToInsure: string;
  flatLevel: string;
  residencyType: string;
  fireCoverage: boolean;
  waterCoverage: boolean;
  theftCoverage: boolean;
  glassBreakCoverage: boolean;
  publicLiabilityCoverage: boolean;
  feeCancellationCoverage: boolean;
  electricalDamageCoverage: boolean;
  poolCoverage: boolean;
  dogCoverage: boolean;
  currentInsuranceCompany: string;
};

export type ContractCarFormModel = {
  contractModel: ContractModel;
  carInsuranceContractFormModel: CarInsuranceContractFormModel;
  carInsuranceContractDisasterDetailFormModels: CarInsuranceContractDisasterDetailFormModel[];
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

export type CarInsuranceContractFormModel = {
  driverGender: string;
  driverFirstName: string;
  driverLastName: string;
  driverBirthdate: Date;
  driverZipCode: string;
  driverCity: string;
  driverAddress: string;
  driverMaritalStatus: string;
  licenseDate: Date;
  isDriverVehiculeRegistrationHolder: boolean;
  vehiculeRegistrationHolder: string;
  vehiculeRegistrationHolderNameLastName: string;
  vehiculeRegistrationHolderNameFirstName: string;
  vehiculeRegistrationHolderCompanyName: string;
  vehiculeRegistrationHolderBirthdate: Date;
  yearsInsured: string;
  bonusMalus: string;
  monthInsuranceInterruption: string;
  insuranceTermination: string;
  licenseAnnulation: string;
  noLicenseConviction: string;
  licenseCancel: string;
  licenseCancelMotive: string;
  licenseCancelDate: Date;
  licenseSuspension: string;
  licenseSuspensionMotive: string;
  licenseSuspensionDate: Date;
  longestSuspension: string;
  alcoholDrugControl: string;
  nbCarDisaster: string;
  licensePlateNumber: string;
  brand: string;
  model: string;
  exactModel: string;
  carBody: string;
  carEnergie: string;
  carPower: string;
  vehiculeUsage: string;
  vehiculeUsageProSupp: string;
  parkingType: string;
  dateOfCirculation: Date;
  contractStartDate: Date;
  insuranceCoverage: string;
  currentInsuranceCompany: string;
  driverBodyCoverage: boolean;
  replacementVehicleCoverage: boolean;
  driverPropertyCoverage: boolean;
  brandNewCoverage: boolean;
  moneyLossCoverage: boolean;
};

export type CarInsuranceContractDisasterDetailFormModel = {
  carDisasterDate: Date;
  carDisasterReason: string;
  carDisasterResponsiblePerson: string;
};

export type ContractHealthUpdateModel = {
  contractModel: ContractModel;
  healthInsuranceContractDetailFormModels: HealthInsuranceContractDetailFormModel[];
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

// export type ContractUpdateModel = {
//   id: number;
//   category: InsuranceCategory;
//   productCategoryId?: number;
//   productCategoryName: string;
//   salesTeamId: number | undefined | null;
//   salesPersonId: number | undefined | null;
//   campaignId: number | undefined | null;
//   medium: string;
//   source: string;
//   monthlyFee: number;
//   // creationDate: Date;
//   companyKey: string;
//   productLineKey: string;
//   productKey: string;
//   statusId: ContractStatus;
//   productLineId: number | undefined | null;
//   productId: number | undefined | null;
//   paymentSplittingId: number | undefined | null;
//   startDate: Date;
//   endDate: Date | undefined | null;
//   name: string;
//   subscriptionDate: Date | undefined;
//   documentsReceivedDate: Date | undefined;
//   documentsSentDate: Date | undefined;
//   regularizationDate: Date | undefined;
//   expiryMonth: number | undefined;
//   expiryDay: number | undefined;
//   terminationDate: Date | undefined;
//   terminationReasonId: number | undefined;
//   applicationFees: number | undefined;
//   monthlyCost: number | undefined;
//   commissionRate: number | undefined;
//   taxRate: number | undefined;
//   validationDate: Date | undefined;
//   approverId: number | undefined;
//   contractManagerId: number;
//   contactId: number;
// };

// export type BankAccountUpdateModel = {
//   iban: string;
//   bic: string;
//   holderName: string;
//   active: boolean;
// };

export type ContractCarUpdateModel = {
  contractModel: ContractModel;
  carInsuranceContractUpdateModel: CarInsuranceContractUpdateModel;
  carInsuranceContractDisasterDetailFormModels: CarInsuranceContractDisasterDetailFormModel[];
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

export type CarInsuranceContractUpdateModel = {
  driverGender: string;
  driverFirstName: string;
  driverLastName: string;
  driverBirthdate: Date;
  driverZipCode: string;
  driverCity: string;
  driverAddress: string;
  driverMaritalStatus: string;
  licenseDate?: Date;
  isDriverVehiculeRegistrationHolder: boolean;
  vehiculeRegistrationHolder: string;
  vehiculeRegistrationHolderNameLastName: string;
  vehiculeRegistrationHolderNameFirstName: string;
  vehiculeRegistrationHolderCompanyName: string;
  vehiculeRegistrationHolderBirthdate?: Date;
  yearsInsured: string;
  bonusMalus: string;
  monthInsuranceInterruption: string;
  insuranceTermination: string;
  licenseAnnulation: string;
  noLicenseConviction: string;
  licenseCancel: string;
  licenseCancelMotive: string;
  licenseCancelDate?: Date;
  licenseSuspension: string;
  licenseSuspensionMotive: string;
  licenseSuspensionDate?: Date;
  longestSuspension: string;
  alcoholDrugControl: string;
  nbCarDisaster: string;
  licensePlateNumber: string;
  brand: string;
  model: string;
  exactModel: string;
  carBody: string;
  carEnergie: string;
  carPower: string;
  vehiculeUsage: string;
  vehiculeUsageProSupp: string;
  parkingType: string;
  dateOfCirculation?: Date;
  contractStartDate?: Date;
  insuranceCoverage: string;
  currentInsuranceCompany: string;
  driverBodyCoverage: boolean;
  replacementVehicleCoverage: boolean;
  driverPropertyCoverage: boolean;
  brandNewCoverage: boolean;
  moneyLossCoverage: boolean;
};

export type ContractHouseUpdateModel = {
  contractModel: ContractModel;
  houseInsuranceContractFormModel: HouseInsuranceContractFormModel;
  paymentBankAccountModel: BankAccountModel;
  refundingBankAccountModel: BankAccountModel;
};

export type ChildAffiliation = 'Subscriber' | 'Partner' | 'None';

export type CreateContractEntityModel = {
  productCategoryId: number | undefined;
  // category: string;
  leadId: string;
  productId: string;
};
