import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatestWith, filter, Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { RegisterModel, User, UserModel } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import { View } from '../../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../../shared/store/form/form.service';
import { DropdownStyle } from '../../../../../shared/store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
  result$: Observable<User>;
  registerInfo: RegisterModel;
  view$: Observable<View>;
  userForm: FormGroup;
  currentUser$: Observable<User>;

  imageUrl: string = '';
  file: File | undefined;

  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly userService: UserService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.result$ = this.userService.add.value$;
    this.view$ = this.viewService.getViews.value$;
    this.currentUser$ = this.userService.getCurrentUser.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'userCreate') this.onSubmit();
        if (x == 'userList') {
          this.formService.clear();
          this.router.navigate(['Configuration/Users/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@userCreate', x.breadcrumbCreate);
      });

    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      roles: [],
      pictureId: [null],
      pictureFile: [undefined],
      salesPersonCommissionPlanId: [undefined],
      sharedCommissionPlanId: [undefined],
      salesTeamIds: [undefined],
    });
  }

  onSubmit(): void {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.formService.countErrors(this.userForm, true);
      return;
    }

    let userModel: UserModel = {
      firstName: this.userForm.value.firstName!,
      lastName: this.userForm.value.lastName!,
      email: this.userForm.value.email!,
      roles: this.userForm.value.roles!,
      contactPictureId: this.userForm.value.pictureId!,
      contactPictureFile: this.file,
      salesPersonCommissionPlanId:
        this.userForm.value.salesPersonCommissionPlanId!,
      sharedCommissionPlanId: this.userForm.value.sharedCommissionPlanId!,
      salesTeamIds: this.userForm.value.salesTeamIds!,
    };
    let result$ = this.userService.add.call(userModel);

    result$
      .pipe(
        combineLatestWith(this.currentUser$),
        filter(([result, currentUser]) => !!result && !!currentUser),
        take(1),
      )
      .subscribe(([result, currentUser]) => {
        this.formService.clear();
        if (result.id == currentUser.id) {
          this.userService.getCurrentUser.call();
        }
        this.router.navigate(['Configuration/Users/Details/', result.id]);
      });
  }

  get email() {
    return this.userForm.get('email')!;
  }

  get password() {
    return this.userForm.get('password')!;
  }

  ngOnDestroy(): void {
    this.userService.add.reset();
  }

  onAvatarChange(file: File | undefined) {
    if (file) {
      this.file = file;
    } else {
      this.userForm.patchValue({
        pictureId: undefined,
      });
    }
  }
}
