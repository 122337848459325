import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  take,
} from 'rxjs';
import { GenericApiCall } from 'src/app/common/store/types';
import { formatDate } from 'src/app/shared/helper/datehelper';
import {
  removeValidators,
  setValidators,
} from 'src/app/shared/helper/formhelper';
import { filterTrue } from 'src/app/shared/pipe/rxjs/operators';
import { ModalService } from 'src/app/shared/service/modal.service';
import { AccountingService } from 'src/app/shared/store/accounting/accounting.service';
import {
  AccountModel,
  AccountMoveInstanceParams,
  AccountMoveLineModel,
  AccountMoveModel,
  AccountMovePeriodModel,
} from 'src/app/shared/store/accounting/types';
import { FormService } from 'src/app/shared/store/form/form.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from 'src/app/shared/store/typeahead/types';
import { Entity, View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import {
  ApplicationFeeRuleModel,
  ApplicationFeeType,
} from '../../../store/application-fee-rule/types';
import { ApplicationFeeRuleService } from '../../../store/application-fee-rule/application-fee-rule.service';
import { AmountType } from '../../../store/commissions/commission-plan-rule/types';
import { ShowSignValueTypePipe } from '../../../pipe/show-sign-value-type.pipe';
import { getAmountSign } from 'src/app/shared/helper/amounthelper';

@UntilDestroy()
@Component({
  selector: 'app-account-move-add-content',
  templateUrl: './account-move-add-content.component.html',
  styleUrls: ['./account-move-add-content.component.scss'],
})
export class AccountMoveAddContentComponent {
  @Input() modalRandomIdentifier: number;
  @Input() contractId: number;
  @Input() isClient: boolean;
  @Input() contractInsuranceCompanyContactId: number;
  @Input() source: SourceEnum = SourceEnum.Component;

  view$: Observable<View>;
  paymentForm: FormGroup;
  accountMoveInstance$: Observable<AccountMoveModel>;
  accountMovePeriodsModels$: Observable<AccountMovePeriodModel[]>;
  account$: Observable<AccountModel>;
  accountId: number = 0;
  applicationFeeRuleModel$: Observable<ApplicationFeeRuleModel>;

  apiCall: GenericApiCall<any, any>;
  isRecurringFees: boolean;
  accountToDomain: string = '';

  defaultAmountSign: AmountType = 'Monetary';
  unitPricePlaceholder: string;
  unitPriceSuffix: string;
  saveValue: { minValue: ValidatorFn; maxValue: ValidatorFn } | undefined;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
    private readonly dynamicService: ModalService,
    private readonly applicationFeeRuleService: ApplicationFeeRuleService,
    private readonly showSignValueType: ShowSignValueTypePipe,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.accountMovePeriodsModels$ = this.accountingService.getPeriods.value$;
    this.accountMoveInstance$ = this.accountingService.createInstance.value$;
    this.account$ = this.accountingService.getAccountByContactId.value$;
    this.applicationFeeRuleModel$ =
      this.applicationFeeRuleService.getByContractId.value$;
  }

  ngOnInit(): void {
    this.unitPricePlaceholder = this.unitPriceSuffix = getAmountSign(
      this.defaultAmountSign,
    );

    const brokerDomain: string = '(accountType=2,active=1)';
    const insuranceCompanyDomain: string =
      '|(contactId=' +
      this.contractInsuranceCompanyContactId +
      ',active=1,accountType=1)';
    this.accountToDomain = brokerDomain + insuranceCompanyDomain;
    this.isRecurringFees = false;
    this.apiCall = this.accountingService.getPeriods;
    this.accountingService.getAccountByContactId.call(
      this.contractInsuranceCompanyContactId.toString(),
    );

    let accountMoveInstanceParams: AccountMoveInstanceParams = {
      contractId: this.contractId,
    };

    accountMoveInstanceParams.moveType = this.isClient
      ? 'CustomerInvoice'
      : 'VendorInvoice';

    this.accountingService.getPeriods.call(this.contractId);
    this.accountingService.createInstance.call(accountMoveInstanceParams);

    this.paymentForm = this.formBuilder.group({
      accountMoveName: [undefined, [Validators.required]],
      accountMoveLineName: [],
      contactId: [undefined, [Validators.required]],
      unitPrice: [undefined, [Validators.required]],
      moveType: [undefined],
      salesPersonId: [],
      creditAccountId: [undefined, [Validators.required]],
      debitAccountId: [undefined, [Validators.required]],
      contractId: [],
      accountMovePaymentStatus: [],
      accountMoveStatus: [],
      autoPost: [],
      quantity: [0],
      debit: [0],
      credit: [0],
      currencyId: [],
      discount: [0],
      description: [],
      paymentMode: [],
      directionSign: [],
      paymentReference: [''],
      status: [],
      sequence: [],
      accountMoveLinePaymentStatus: [],
      accountMovePeriod: [],
      periodStartDate: [],
      periodEndDate: [],
    });

    this.accountMoveInstance$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.paymentForm.patchValue({
          accountMoveName: x.name,
          accountMovePaymentStatus: x.paymentStatus,
          contractId: x.contractId,
          directionSign: x.directionSign,
          status: x.status,
          moveType: x.moveType,
          autoPost: x.autoPost,
          dueDate: x.deliveryDate,
          salesPersonId: x.salesPersonId,
          accountMoveStatus: x.status,
          creationDate: new Date(),
          currencyId: x.accountMoveLines[0].currencyId,
          sequence: x.accountMoveLines[0].sequence,
          quantity: x.accountMoveLines[0].quantity,
          // unitPrice: x.accountMoveLines[0].unitPrice,
          discount: x.accountMoveLines[0].discount,
          debit: x.accountMoveLines[0].debit,
          credit: x.accountMoveLines[0].credit,
          accountMoveLinePaymentStatus: x.accountMoveLines[0].paymentStatus,
          contactId: x.contactId,
          debitAccountId: this.isClient ? x.debitAccountId : undefined,
          creditAccountId: this.isClient ? undefined : x.creditAccountId,
        });
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMoveCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.paymentForm.controls['accountMovePeriod']?.valueChanges
      .pipe(debounceTime(150))
      .subscribe((accountMovePeriodName) => {
        this.accountMovePeriodsModels$
          .pipe(filterTrue(), untilDestroyed(this))
          .subscribe((periods) => {
            const period = periods.find((x) => x.name == accountMovePeriodName);
            this.paymentForm.patchValue({
              periodStartDate: formatDate(
                period?.startDate!,
                'ToInverseSqlDate',
              ),
              periodEndDate: formatDate(period?.endDate!, 'ToInverseSqlDate'),
            });
          });
      });

    this.paymentForm.controls['accountMoveName']?.valueChanges
      .pipe(debounceTime(150))
      .subscribe((accountMoveName: string) => {
        if (accountMoveName == 'Echéance') {
          this.isRecurringFees = true;
          setValidators(
            this.paymentForm.get(['periodStartDate'] as const),
            Validators.required,
          );
          setValidators(
            this.paymentForm.get(['periodEndDate'] as const),
            Validators.required,
          );
          this.accountToDomain = brokerDomain;
          this.applicationFeeRuleService.getByContractId.reset();
          if (
            this.paymentForm.get(['creditAccountId'])?.value === this.accountId
          ) {
            this.paymentForm.patchValue({
              creditAccountId: undefined,
            });
          }
        } else {
          this.isRecurringFees = false;
          removeValidators(
            this.paymentForm.get(['periodStartDate'] as const),
            Validators.required,
          );
          removeValidators(
            this.paymentForm.get(['periodEndDate'] as const),
            Validators.required,
          );
          if (!accountMoveName || accountMoveName == 'Frais de dossier') {
            this.accountToDomain = brokerDomain + insuranceCompanyDomain;
          } else {
            this.accountToDomain = brokerDomain;
            this.applicationFeeRuleService.getByContractId.reset();
            if (
              this.paymentForm.get(['creditAccountId'])?.value ===
              this.accountId
            ) {
              this.paymentForm.patchValue({
                creditAccountId: undefined,
              });
            }
          }
        }
      });

    this.paymentForm
      .get('creditAccountId')
      ?.valueChanges?.pipe(distinctUntilChanged(), untilDestroyed(this))
      ?.subscribe((value) => {
        if (!!value && !!this.accountId && value === this.accountId) {
          if (
            this.paymentForm.get('accountMoveName')?.value !==
            'Frais de dossier'
          ) {
            this.paymentForm.patchValue({
              accountMoveName: 'Frais de dossier',
            });
          }
          this.applicationFeeRuleService.getByContractId.call({
            id: this.contractId,
            type: 'Vendor',
          });
        } else {
          if (
            !!value &&
            this.paymentForm.get('accountMoveName')?.value ===
              'Frais de dossier'
          ) {
            this.applicationFeeRuleService.getByContractId.call({
              id: this.contractId,
              type: 'Customer',
            });
          }
        }
        if (
          !value ||
          this.paymentForm.get('accountMoveName')?.value !== 'Frais de dossier'
        ) {
          this.applicationFeeRuleService.getByContractId.reset();
        }
      });

    this.account$
      .pipe(filterTrue(), take(1))
      .subscribe((account: AccountModel) => {
        this.accountId = 0;
        if (account) {
          this.accountId = account.id;
        }
      });

    this.applicationFeeRuleModel$
      .pipe(untilDestroyed(this))
      .subscribe((applicationFeeRule) => {
        if (applicationFeeRule) {
          this.paymentForm.patchValue({
            unitPrice: applicationFeeRule.defaultValue,
          });

          //save min and max
          this.saveValue = {
            minValue: Validators.min(applicationFeeRule.minimumValue),
            maxValue: Validators.max(applicationFeeRule.maximumValue),
          };

          //set min
          setValidators(
            this.paymentForm.get(['unitPrice'] as const),
            this.saveValue.minValue,
          );

          //set max
          setValidators(
            this.paymentForm.get(['unitPrice'] as const),
            this.saveValue.maxValue,
          );

          //update suffix
          this.unitPriceSuffix = getAmountSign(
            this.defaultAmountSign,
            applicationFeeRule.amountType,
          );

          //update placeholder
          this.unitPricePlaceholder =
            this.showSignValueType.transform(
              applicationFeeRule.minimumValue,
              applicationFeeRule.amountType,
            ) +
            ' - ' +
            this.showSignValueType.transform(
              applicationFeeRule.maximumValue,
              applicationFeeRule.amountType,
            );
        } else {
          this.unitPricePlaceholder = this.unitPriceSuffix = getAmountSign(
            this.defaultAmountSign,
          );

          //no rule
          if (this.saveValue) {
            //unset min
            removeValidators(
              this.paymentForm.get(['unitPrice'] as const),
              this.saveValue.minValue,
            );

            //unset max
            removeValidators(
              this.paymentForm.get(['unitPrice'] as const),
              this.saveValue.maxValue,
            );
          }
          //reset errors
          this.paymentForm.get(['unitPrice'] as const)?.setErrors(null);

          //remove value
          this.paymentForm.patchValue({
            unitPrice: undefined,
          });

          //reset saveValue
          this.saveValue = undefined;
        }
      });
  }

  onSubmit() {
    debugger;
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      this.formService.setEntityErrors('PAYMENT');
      this.formService.countErrors(this.paymentForm, true);
      return;
    }

    let accountMoveLine: AccountMoveLineModel = {
      id: 0,
      accountMoveId: 0,
      currencyId: this.paymentForm.value.currencyId,
      name: this.paymentForm.value.accountMoveLineName,
      unitPrice: this.paymentForm.value.unitPrice,
      paymentModeLoc: '',
      paymentStatusLoc: '',
      sequence: this.paymentForm.value.sequence,
      quantity: this.paymentForm.value.quantity,
      debit: this.paymentForm.value.debit,
      credit: this.paymentForm.value.unitPrice,
      creationDate: new Date(),
      paymentMode: this.paymentForm.value.paymentMode,
      paymentStatus: this.paymentForm.value.accountMoveLinePaymentStatus,
      description: this.paymentForm.value.description,
      discount: this.paymentForm.value.discount,
    };

    let accountMoveLines: AccountMoveLineModel[] = [accountMoveLine];

    let name = this.paymentForm.value.accountMoveName;
    if (this.isRecurringFees) {
      if (this.paymentForm.value.accountMovePeriod != null) {
        name = `${this.paymentForm.value.accountMoveName} ${this.paymentForm.value.accountMovePeriod}`;
      } else {
        name = this.paymentForm.value.accountMoveName;
      }
    }

    let accountMoveModel: AccountMoveModel = {
      id: 0,
      contactId: this.paymentForm.value.contactId,
      currencyId: this.paymentForm.value.currencyId,
      salesPersonId: this.paymentForm.value.salesPersonId,
      creditAccountId: this.paymentForm.value.creditAccountId,
      debitAccountId: this.paymentForm.value.debitAccountId,
      contractId: this.contractId,
      paymentStatus: this.paymentForm.value.accountMovePaymentStatus,
      paymentStatusLoc: '',
      name: name,
      reference: this.paymentForm.value.accountMoveLineName,
      directionSign: this.paymentForm.value.directionSign,
      status: this.paymentForm.value.accountMoveStatus,
      statusLoc: '',
      moveType: this.paymentForm.value.moveType,
      moveTypeLoc: '',
      paymentReference: this.paymentForm.value.paymentReference,
      autoPost: this.paymentForm.value.autoPost,
      deliveryDate: this.paymentForm.value.dueDate,
      creationDate: new Date(),
      accountMoveLines: accountMoveLines,
      issuingAccountMovesCount: 0,
      payingAccountMovesCount: 0,
      periodEndDate: this.paymentForm.value.periodEndDate,
      periodStartDate: this.paymentForm.value.periodStartDate,
      period: '',
      payingAccountMoves: [],
      issuingAccountMoves: [],
      repaymentAccountMoves: [],
      actions: [],
      journalName: '',
      creditAccountName: '',
      debitAccountName: '',
    };

    if (this.isClient) {
      accountMoveModel.creditAccountId = this.paymentForm.value.creditAccountId;
    } else {
      accountMoveModel.debitAccountId = this.paymentForm.value.debitAccountId;
    }

    let result$ = this.accountingService.add.call(accountMoveModel);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          // if (result.id == user.contactId) {
          //   this.userService.getCurrentUser.call();
          // }
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(
            this.modalRandomIdentifier,
            result.id.toString(),
          );
          break;
        default:
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
      }
    });

    this.formService.clear();
  }

  ngOnDestroy(): void {
    this.accountingService.add.reset();
  }
}
