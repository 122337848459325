import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../helper/datehelper';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalService } from '../../../service/modal.service';
import { AccountingService } from '../../../store/accounting/accounting.service';
import {
  AccountMoveInstanceParams,
  AccountMoveLineModel,
  AccountMoveModel,
} from '../../../store/accounting/types';
import { FormService } from '../../../store/form/form.service';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../store/typeahead/types';
import { Entity, View } from '../../../store/view/types';
import { GenericApiCall } from '../../../../common/store/types';

@UntilDestroy()
@Component({
  selector: 'app-payment-add',
  templateUrl: './payment-add-content.component.html',
  styleUrls: ['./payment-add-content.component.scss'],
})
export class PaymentAddContentComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input({ required: true }) contactId: number;
  @Input({ required: true }) contactIsCompany: boolean;
  @Input() isReimbursement: boolean;
  @Input() isCompany: boolean = false;
  @Input() source: SourceEnum = SourceEnum.Component;

  accountMovePaymentCreateInstance$: Observable<AccountMoveModel>;
  paymentAccountMoveModel: AccountMoveModel;

  paymentForm: FormGroup;

  apiCall: GenericApiCall<any, any>;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly dynamicService: ModalService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
  ) {
    this.accountMovePaymentCreateInstance$ =
      this.accountingService.createInstance.value$;
  }

  ngOnInit(): void {
    this.apiCall = this.accountingService.createInstance;

    this.paymentForm = this.formBuilder.group({
      fromAccountId: [undefined, [Validators.required]],
      toAccountId: [undefined, [Validators.required]],
      amount: [undefined, [Validators.required]],
      paymentMode: [
        this.contactIsCompany && this.isReimbursement
          ? 'BankTransfert'
          : undefined,
        [Validators.required],
      ],
      date: [formatDate(new Date(), 'ToInverseSqlDate'), [Validators.required]],
      reference: [''],
      proofFile: [],
    });

    const accountMoveInstanceParams: AccountMoveInstanceParams = {
      moveType: 'Entry',
    };

    this.accountingService.createInstance.call(accountMoveInstanceParams);

    this.accountMovePaymentCreateInstance$
      .pipe(filterTrue(), take(1))
      .subscribe((x) => {
        this.paymentAccountMoveModel = {
          id: 0,
          autoPost: x.autoPost,
          contactId: x.contactId,
          contractId: x.contractId,
          creationDate: x.creationDate,
          currencyId: x.currencyId,
          creditAccountId: x.creditAccountId,
          creditAccountName: x.creditAccountName,
          debitAccountId: x.debitAccountId,
          debitAccountName: x.debitAccountName,
          deliveryDate: x.deliveryDate,
          directionSign: x.directionSign,
          moveType: x.moveType,
          moveTypeLoc: x.moveTypeLoc,
          name: x.name,
          paymentReference: x.paymentReference,
          paymentStatus: x.paymentStatus,
          paymentStatusLoc: x.paymentStatusLoc,
          reference: x.reference,
          salesPersonId: x.salesPersonId,
          status: x.status,
          statusLoc: x.statusLoc,
          accountMoveLines: x.accountMoveLines,
          journalId: x.journalId,
          journalName: x.journalName,
          issuingAccountMovesCount: x.issuingAccountMovesCount,
          payingAccountMovesCount: x.payingAccountMovesCount,
          periodEndDate: new Date(),
          periodStartDate: new Date(),
          period: '',
          issuingAccountMoves: [],
          payingAccountMoves: [],
          repaymentAccountMoves: [],
          actions: [],
        };
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'paymentCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      this.formService.setEntityErrors('PAYMENT');
      this.formService.countErrors(this.paymentForm, true);
      return;
    }

    let accountMoveModel: AccountMoveModel = {
      debitAccountId: this.paymentForm.value.fromAccountId,
      creditAccountId: this.paymentForm.value.toAccountId,
      contactId: this.contactId,
      reference: this.paymentForm.value.reference!,

      id: 0,
      autoPost: this.paymentAccountMoveModel.autoPost,
      contractId: this.paymentAccountMoveModel.contractId,
      creationDate: this.paymentAccountMoveModel.creationDate,
      currencyId: this.paymentAccountMoveModel.currencyId,
      deliveryDate: this.paymentAccountMoveModel.deliveryDate,
      directionSign: this.paymentAccountMoveModel.directionSign,
      moveType: this.paymentAccountMoveModel.moveType,
      moveTypeLoc: this.paymentAccountMoveModel.moveTypeLoc,
      name: this.paymentAccountMoveModel.name,
      paymentReference: this.paymentAccountMoveModel.paymentReference,
      paymentStatus: this.paymentAccountMoveModel.paymentStatus,
      paymentStatusLoc: this.paymentAccountMoveModel.paymentStatusLoc,
      salesPersonId: this.paymentAccountMoveModel.salesPersonId,
      status: this.paymentAccountMoveModel.status,
      statusLoc: this.paymentAccountMoveModel.statusLoc,
      periodStartDate: new Date(),
      periodEndDate: new Date(),
      period: '',
      accountMoveLines: [],
      issuingAccountMovesCount: 0,
      payingAccountMovesCount: 0,
      issuingAccountMoves: [],
      payingAccountMoves: [],
      repaymentAccountMoves: [],
      actions: [],
      journalName: '',
      creditAccountName: '',
      debitAccountName: '',
    };

    let unitPrice = this.paymentForm.value.amount;
    if (this.isReimbursement) {
      if (this.isCompany) {
        unitPrice = this.paymentForm.value.amount;
      } else {
        unitPrice = this.paymentForm.value.amount * -1;
      }
    }

    let accountMoveLineModel: AccountMoveLineModel = {
      paymentMode: this.paymentForm.value.paymentMode,
      unitPrice: unitPrice,
      contactId: this.contactId,
      proofFile: this.paymentForm.value.proofFile,

      accountMoveId:
        this.paymentAccountMoveModel.accountMoveLines[0].accountMoveId,
      creationDate:
        this.paymentAccountMoveModel.accountMoveLines[0].creationDate,
      credit: this.paymentAccountMoveModel.accountMoveLines[0].credit,
      currencyId: this.paymentAccountMoveModel.accountMoveLines[0].currencyId,
      debit: this.paymentAccountMoveModel.accountMoveLines[0].debit,
      description: this.paymentAccountMoveModel.accountMoveLines[0].description,
      discount: this.paymentAccountMoveModel.accountMoveLines[0].discount,
      name: this.paymentAccountMoveModel.accountMoveLines[0].name,
      paymentModeLoc:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentModeLoc,
      paymentStatus:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentStatus,
      paymentStatusLoc:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentStatusLoc,
      quantity: this.paymentAccountMoveModel.accountMoveLines[0].quantity,
      sequence: this.paymentAccountMoveModel.accountMoveLines[0].sequence,

      id: 0,
    };

    switch (accountMoveLineModel.paymentMode) {
      case 'Cash':
      case 'Card':
        accountMoveLineModel.paymentStatus = 'Paid';
        break;
      default:
        break;
    }

    accountMoveModel.accountMoveLines = [accountMoveLineModel];

    let result$ = this.accountingService.add.call(accountMoveModel);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          // if (result.id == user.contactId) {
          //   this.userService.getCurrentUser.call();
          // }
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(
            this.modalRandomIdentifier,
            result.id.toString(),
          );
          break;
        default:
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.accountingService.createInstance.reset();
    this.accountingService.add.reset();
  }

  onFileEmitterProof(file: File | undefined) {
    this.paymentForm.patchValue({
      proofFile: file,
    });
  }
}
