import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CompanyCommissionRoutingModule } from './company-commission-routing.module';
import { CompanyCommissionComponent } from './company-commission/company-commission.component';

@NgModule({
  declarations: [CompanyCommissionComponent],
  imports: [CommonModule, CompanyCommissionRoutingModule, SharedModule],
})
export class CompanyCommissionModule {}
