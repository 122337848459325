import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatestWith, filter, Observable } from 'rxjs';
import { Entity, View } from 'src/app/shared/store/view/types';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../../../shared/store/typeahead/types';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { ModalService } from '../../../../../shared/service/modal.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ContactModel } from '../../../../../shared/store/contact/types';
import { formatDate } from '../../../../../shared/helper/datehelper';
import { AddressEdit } from '../../../../../shared/components/address-edit/address-edit';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../shared/store/user/user.service';
import { User } from '../../../../../shared/store/user/type';

@UntilDestroy()
@Component({
  selector: 'app-contact-form-content',
  templateUrl: './contact-form-content.component.html',
  styleUrls: ['./contact-form-content.component.scss'],
})
export class ContactFormContentComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  contactModel$: Observable<ContactModel>;
  createContactInstance$: Observable<ContactModel>;
  user$: Observable<User>;

  contactForm: FormGroup;

  isEditMode: boolean = false;
  contactId: string;

  @Input() modalRandomIdentifier: number;
  @Input() modalName: string;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Output() modalEvent = new EventEmitter<number>();

  @Input() parentId?: number;

  imageUrl: string = '';
  file: File | undefined;

  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;
  protected readonly DataType = DataType;
  protected readonly SourceEnum = SourceEnum;

  constructor(
    private readonly contactService: ContactService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly dynamicService: ModalService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.contactModel$ = this.contactService.getById.value$;
    this.createContactInstance$ = this.contactService.createInstance.value$;
    this.user$ = this.userService.getCurrentUser.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.contactForm = this.formBuilder.group({
      isCompany: [false],
      company: [undefined],
      name: ['', [Validators.required]],
      firstName: [''],
      lastName: [''],
      titleId: [undefined],
      // addressType: [1, [Validators.required]],
      contactAddress: this.formBuilder.group<AddressEdit>({
        address: [''],
        address2: [''],
        zipCode: [''],
        city: [undefined],
        countryId: [undefined],
      }),
      phoneNumber: [''],
      mobilePhoneNumber: [''],
      email: ['', [Validators.email]],
      profession: [''],
      salesTeamId: [undefined],
      salesPersonId: [undefined],
      empty: [''],
      pictureId: [null],
      pictureFile: [undefined],
      uniqueId: [undefined],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.contactId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.contactId;

    //Si on est en mode modal, alors on split le nom en nom et prénom
    if (this.source == SourceEnum.Modal) {
      if (this.modalName) {
        this.contactForm.get('name')?.setValue(this.modalName);
      }

      //Si on est en mode modal avec un parentId, on force le mode en Create, on reset le formulaire et on patch avec la valeur du parent
      if (this.parentId) {
        this.isEditMode = false;
        this.contactForm.reset();
        this.contactForm.patchValue({
          isCompany: false,
          company: this.parentId,
        });
        // this.OnChangeIsCompany(false);
      }
    }
    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.contactService.getById.call(this.contactId);
    } else {
      this.contactService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contactCreate' || x == 'contactUpdate') this.onSubmit();
        if (x == 'contactList') {
          this.formService.clear();
          this.router.navigate(['/Contacts/Contacts/']);
        }
        if (x == 'contactDetails') {
          this.formService.clear();
          this.router.navigate(['/Contacts/Contacts/Details', this.contactId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set('@contactCreate', x.breadcrumbCreate);
      });

    if (this.isEditMode) {
      this.contactModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          // if (this.source == SourceEnum.Component) {
          if (x.isCompany) {
            this.contactService.getChildren.call(x.id);
          }
          this.breadcrumbService.set('@contactEdit', x.name);
          if (x.picturePath) {
            this.imageUrl = environment.comparanooapiurl + x.picturePath;
          }
          // this.OnChangeIsCompany(x.isCompany);
          this.contactForm.patchValue({
            company: x.parentId,
            isCompany: x.isCompany,
            name: x.name,
            firstName: x.firstName,
            lastName: x.lastName,
            titleId: x.titleId,
            addressType: x.addressType,
            contactAddress: {
              address: x.address,
              address2: x.address2,
              zipCode: x.zipCode,
              city: x.city,
              countryId: x.countryId,
            },
            phoneNumber: x.phoneNumber,
            mobilePhoneNumber: x.mobilePhoneNumber,
            email: x.email,
            profession: x.profession,
            marketingFormGroup: {
              marketingCampaignId: x.campaignId,
              marketingMedium: x.medium,
              marketingSource: x.source,
              marketingCreationDate: formatDate(
                x.creationTime,
                'ToInverseSqlDate',
              ),
              salesTeamId: x.salesTeamId,
              salesPersonId: x.salesPersonId,
            },
            notesFormGroup: {
              notes: x.notes,
            },
            pictureId: x.pictureId,
            uniqueId: x.uniqueId,
          });
          // }
        });
    }

    if (!this.isEditMode) {
      this.createContactInstance$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.contactForm?.patchValue({
            marketingFormGroup: {
              marketingSource: x.source,
              salesPersonId: x.salesPersonId,
            },
          });
        });
    }
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.formService.setEntityErrors('CONTACT');
      this.formService.countErrors(this.contactForm, true);
      return;
    }

    let contactModel: ContactModel = {
      id: this.contactId ? +this.contactId : 0,

      //Name Management
      name: this.contactForm.value.name,
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      titleId: this.contactForm.value.titleId,
      parentId: this.contactForm.value.company,

      //Flags
      isCompany: this.contactForm.value.isCompany,

      //Informations
      gender: undefined,
      profession: this.contactForm.value.profession,
      phoneNumber: this.contactForm.value.phoneNumber,
      mobilePhoneNumber: this.contactForm.value.mobilePhoneNumber,
      email: this.contactForm.value.email,

      //Address
      addressType: 1,
      address: this.contactForm.value.contactAddress.address,
      address2: this.contactForm.value.contactAddress.address2,
      zipCode: this.contactForm.value.contactAddress.zipCode,
      city: this.contactForm.value.contactAddress.city,
      countryId: this.contactForm.value.contactAddress.countryId,

      //Sales
      salesTeamId: this.contactForm.value.marketingFormGroup?.salesTeamId,
      salesPersonId: this.contactForm.value.marketingFormGroup?.salesPersonId,
      campaignId:
        this.contactForm.value.marketingFormGroup?.marketingCampaignId,
      medium: this.contactForm.value.marketingFormGroup?.marketingMedium,
      source: this.contactForm.value.marketingFormGroup?.marketingSource,

      //Notes
      notes: this.contactForm.value.notesFormGroup?.notes,

      //Picture
      pictureId: this.contactForm.value.pictureId!,
      pictureFile: this.file,

      //Others
      // addressTypeLoc: '',
      campaignName: '',
      // genderLoc: '',
      parentName: '',
      salesPersonName: '',
      salesTeamName: '',
      title: '',
      countryName: '',
      creationTime: new Date(),
      contracts: [],
      leads: [],
      bankAccounts: [],
      documents: [],

      titleName: '',
      uniqueId: this.contactForm.value.uniqueId!,

      bankAccount: undefined,
      contractsCount: 0,
      leadsCount: 0,
      isEmployee: false,
      isInsuranceCompany: false,
      isUser: false,
      accountMoves: [],
    };
    // console.log(contactModel);

    let result$: Observable<ContactModel>;
    if (this.isEditMode)
      result$ = this.contactService.updateContact.call(contactModel);
    else result$ = this.contactService.postContact.call(contactModel);

    // combineLatest([result$, this.user$])
    result$
      .pipe(
        combineLatestWith(this.user$),
        filter(([result, user]) => !!result && !!user),
        take(1),
      )
      .subscribe(([result, user]) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            if (result.id == user.contactId) {
              this.userService.getCurrentUser.call();
            }
            this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result.id.toString(),
            );
            break;
          default:
            this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.contactService.postContact.reset();
    this.contactService.updateContact.reset();

    if (this.isEditMode) {
      this.contactService.getById.reset();
    } else {
      this.contactService.createInstance.reset();
    }

    this.breadcrumbService.set('@contactEdit', ' ');
    this.breadcrumbService.set('@contactCreate', ' ');
  }

  onAvatarChange(file: File | undefined) {
    if (file) {
      this.file = file;
    } else {
      this.contactForm.patchValue({
        pictureId: undefined,
      });
    }
  }
}
