import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../../shared/store/typeahead/types';
import { GenericApiCall } from '../../../../common/store/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '../../../../shared/helper/datehelper';
import { Observable } from 'rxjs';
import {
  AccountMoveInstanceParams,
  AccountMoveLineModel,
  AccountMoveModel,
  AccountMoveTypeFlow,
  CashJournalModel,
} from '../../../../shared/store/accounting/types';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ModalService } from '../../../../shared/service/modal.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { AccountingService } from '../../../../shared/store/accounting/accounting.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '../../../../shared/store/view/types';

@UntilDestroy()
@Component({
  selector: 'app-cash-journal-add',
  templateUrl: './cash-journal-add-content.component.html',
  styleUrl: './cash-journal-add-content.component.scss',
})
export class CashJournalAddContentComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input({ required: true }) type: 'In' | 'Out';
  @Input() contactId: number;

  cashJournalModel$: Observable<CashJournalModel>;
  accountMovePaymentCreateInstance$: Observable<AccountMoveModel>;
  cashJournalForm: FormGroup;
  paymentAccountMoveModel: AccountMoveModel;

  apiCall: GenericApiCall<any, any>;

  excludeSignHack: string = '!=';
  isInitialBalance: boolean = false;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly dynamicService: ModalService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
    private readonly translateService: TranslateService,
  ) {
    this.accountMovePaymentCreateInstance$ =
      this.accountingService.createInstance.value$;
    this.cashJournalModel$ = this.accountingService.getCashJournal.value$;
  }

  ngOnInit(): void {
    this.apiCall = this.accountingService.createInstance;

    const accountMoveInstanceParams: AccountMoveInstanceParams = {
      moveType: 'Entry',
    };

    this.accountingService.createInstance.call(accountMoveInstanceParams);

    this.cashJournalForm = this.formBuilder.group({
      fromAccountId: [undefined, [Validators.required]],
      toAccountId: [undefined, [Validators.required]],
      amount: [undefined, [Validators.required]],
      paymentMode: ['Cash', [Validators.required]],
      date: [formatDate(new Date(), 'ToInverseSqlDate'), [Validators.required]],
      reference: [''],
      proofFile: [],
      description: [''],
    });

    this.accountMovePaymentCreateInstance$
      .pipe(filterTrue(), take(1))
      .subscribe((x) => {
        this.paymentAccountMoveModel = {
          id: 0,
          autoPost: x.autoPost,
          contactId: x.contactId,
          contractId: x.contractId,
          creationDate: x.creationDate,
          currencyId: x.currencyId,
          creditAccountId: x.creditAccountId,
          creditAccountName: x.creditAccountName,
          debitAccountId: x.debitAccountId,
          debitAccountName: x.debitAccountName,
          deliveryDate: x.deliveryDate,
          directionSign: x.directionSign,
          moveType: x.moveType,
          moveTypeLoc: x.moveTypeLoc,
          name: x.name,
          paymentReference: x.paymentReference,
          paymentStatus: x.paymentStatus,
          paymentStatusLoc: x.paymentStatusLoc,
          reference: x.reference,
          salesPersonId: x.salesPersonId,
          status: x.status,
          statusLoc: x.statusLoc,
          accountMoveLines: x.accountMoveLines,
          journalId: x.journalId,
          journalName: x.journalName,
          issuingAccountMovesCount: x.issuingAccountMovesCount,
          payingAccountMovesCount: x.payingAccountMovesCount,
          periodEndDate: new Date(),
          periodStartDate: new Date(),
          period: '',
          issuingAccountMoves: [],
          payingAccountMoves: [],
          repaymentAccountMoves: [],
          actions: [],
        };
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'addCashEntry') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    //Hack to initialise the balance the first time. To initialise, the debit must be equal to the credit
    this.cashJournalModel$
      .pipe(filterTrue(), take(1))
      .subscribe((cashJournal) => {
        if (cashJournal.cashJournalEntries) {
          const hasInitialBalance = cashJournal.cashJournalEntries.some(
            (entry) =>
              entry.creditAccountId == cashJournal.accountId &&
              entry.debitAccountId == cashJournal.accountId,
          );

          if (!hasInitialBalance) {
            this.excludeSignHack = '=';
            this.isInitialBalance = true;
            this.cashJournalForm.patchValue({
              description: this.translateService.instant(
                'CASH_JOURNAL_LBL_INITIAL_BALANCE',
              ),
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.accountingService.createInstance.reset();
    this.accountingService.add.reset();
  }

  onSubmit() {
    if (this.cashJournalForm.invalid) {
      this.cashJournalForm.markAllAsTouched();
      this.formService.setEntityErrors('CASH_JOURNAL');
      this.formService.countErrors(this.cashJournalForm, true);
      return;
    }

    let accountMoveModel: AccountMoveModel = {
      debitAccountId: this.cashJournalForm.value.fromAccountId,
      creditAccountId: this.cashJournalForm.value.toAccountId,
      contactId: this.contactId,
      reference: this.cashJournalForm.value.reference!,

      id: 0,
      autoPost: this.paymentAccountMoveModel.autoPost,
      contractId: this.paymentAccountMoveModel.contractId,
      creationDate: this.paymentAccountMoveModel.creationDate,
      currencyId: this.paymentAccountMoveModel.currencyId,
      deliveryDate: this.paymentAccountMoveModel.deliveryDate,
      directionSign: this.paymentAccountMoveModel.directionSign,
      moveType: this.paymentAccountMoveModel.moveType,
      moveTypeLoc: this.paymentAccountMoveModel.moveTypeLoc,
      name: this.paymentAccountMoveModel.name,
      paymentReference: this.paymentAccountMoveModel.paymentReference,
      paymentStatus: this.paymentAccountMoveModel.paymentStatus,
      paymentStatusLoc: this.paymentAccountMoveModel.paymentStatusLoc,
      salesPersonId: this.paymentAccountMoveModel.salesPersonId,
      status: this.paymentAccountMoveModel.status,
      statusLoc: this.paymentAccountMoveModel.statusLoc,
      periodStartDate: new Date(),
      periodEndDate: new Date(),
      period: '',
      accountMoveLines: [],
      issuingAccountMovesCount: 0,
      payingAccountMovesCount: 0,
      issuingAccountMoves: [],
      payingAccountMoves: [],
      repaymentAccountMoves: [],
      actions: [],
      journalName: '',
      creditAccountName: '',
      debitAccountName: '',
    };

    let unitPrice = this.cashJournalForm.value.amount;
    if (this.type === 'Out') {
      unitPrice = unitPrice * -1;
    }

    let accountMoveLineModel: AccountMoveLineModel = {
      paymentMode: this.cashJournalForm.value.paymentMode,
      unitPrice: unitPrice,
      contactId: this.contactId,
      proofFile: this.cashJournalForm.value.proofFile,
      description: this.cashJournalForm.value.description,

      accountMoveId:
        this.paymentAccountMoveModel.accountMoveLines[0].accountMoveId,
      creationDate:
        this.paymentAccountMoveModel.accountMoveLines[0].creationDate,
      credit: this.paymentAccountMoveModel.accountMoveLines[0].credit,
      currencyId: this.paymentAccountMoveModel.accountMoveLines[0].currencyId,
      debit: this.paymentAccountMoveModel.accountMoveLines[0].debit,
      discount: this.paymentAccountMoveModel.accountMoveLines[0].discount,
      name: this.paymentAccountMoveModel.accountMoveLines[0].name,
      paymentModeLoc:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentModeLoc,
      paymentStatus:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentStatus,
      paymentStatusLoc:
        this.paymentAccountMoveModel.accountMoveLines[0].paymentStatusLoc,
      quantity: this.paymentAccountMoveModel.accountMoveLines[0].quantity,
      sequence: this.paymentAccountMoveModel.accountMoveLines[0].sequence,

      id: 0,
    };

    switch (accountMoveLineModel.paymentMode) {
      case 'Cash':
      case 'Card':
        accountMoveLineModel.paymentStatus = 'Paid';
        break;
      default:
        break;
    }

    accountMoveModel.accountMoveLines = [accountMoveLineModel];

    let result$ = this.accountingService.add.call(accountMoveModel);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          // if (result.id == user.contactId) {
          //   this.userService.getCurrentUser.call();
          // }
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(
            this.modalRandomIdentifier,
            result.id.toString(),
          );
          break;
        default:
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
      }
    });
  }

  onFileEmitterProof(file: File | undefined) {
    this.cashJournalForm.patchValue({
      proofFile: file,
    });
  }
}
