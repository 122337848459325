import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionMode, View } from '../../../../shared/store/view/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../shared/service/modal.service';
import { ModalContentComponent } from '../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../shared/store/typeahead/types';
import { CashControlService } from '../../../../shared/store/cash-control/cash-control.service';
import { CashControlModel } from '../../../../shared/store/cash-control/types';
import { AccountingService } from '../../../../shared/store/accounting/accounting.service';
import { CashJournalModel } from '../../../../shared/store/accounting/types';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-cash-journal-control',
  templateUrl: './cash-control.component.html',
  styleUrl: './cash-control.component.scss',
})
export class CashControlComponent implements OnInit, OnDestroy {
  cashControlModels$: Observable<CashControlModel[]>;
  cashJournalModel$: Observable<CashJournalModel>;
  view$: Observable<View>;
  salesTeamAccountId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly cashControlService: CashControlService,
    private readonly accountingService: AccountingService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.cashControlModels$ =
      this.cashControlService.getBySalesTeamAccountId.value$;
    this.cashJournalModel$ = this.accountingService.getCashJournal.value$;
  }

  ngOnInit(): void {
    this.salesTeamAccountId = this.route.snapshot.params['id'];
    this.cashControlService.getBySalesTeamAccountId.call(
      this.salesTeamAccountId.toString(),
    );

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.cashJournalModel$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (!!x) {
        this.breadcrumbService.set('@cashJournalDetails', x.accountName);
      } else {
        this.accountingService.getCashJournal.call(
          this.salesTeamAccountId.toString(),
        );
      }
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'cashJournalControl') {
          this.openModalCashControlForm();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  private openModalCashControlForm(id: number = 0) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'CashControlFormContentComponent',
      );
    modalRef.componentInstance.title = 'CASH_JOURNAL_CONTROL_LBL_CONTROL';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'addCashControl',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      salesTeamAccountId: this.salesTeamAccountId,
      cashControlId: id,
    };

    modalRef.result
      .then((id) => {
        this.cashControlService.getBySalesTeamAccountId.call(
          this.salesTeamAccountId.toString(),
        );
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  ngOnDestroy(): void {}

  triggerAction(name: string, id: number) {
    switch (name) {
      case 'updateCashControl':
        this.openModalCashControlForm(id);
        break;
      case 'deleteCashControl':
        this.onDelete(id);
        break;
    }
  }

  private onDelete(id: number) {
    let result$ = this.cashControlService.delete.call(id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.cashControlService.getBySalesTeamAccountId.call(
        this.salesTeamAccountId.toString(),
      );
      this.cashControlService.delete.reset();
    });
  }
}
