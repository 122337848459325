<app-document-subheader/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">
              @if (cashJournalModel$ |async; as cashJournalModel) {
                <div class="row">
                  <div class="col">
                    <app-badge
                      [color]="'primary'"
                      [message]="'CASH_JOURNAL_LBL_BALANCE'  | translate : {balance: cashJournalModel.balance |showSignValueType: 'Monetary'}"
                      [needTranslation]="false"
                    />
                  </div>
                </div>
              }
              <div class="row mt-2">
                <table class="table table-striped border-bottom">
                  <thead>
                  <tr>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_DATE</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_AMOUNT_REAL</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_AMOUNT_CALCULATE</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_AMOUNT_DIFFERENCE</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_SALES_PERSON</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_DESCRIPTION</a>
                    </th>
                    <th>
                      <a translate>CASH_JOURNAL_CONTROL_LBL_ACTIONS</a>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                    @if (cashControlModels$ |async; as cashControlModels) {
                      @for (cashControlModel of cashControlModels; track cashControlModel.id) {
                        <tr>
                          <td>{{ cashControlModel.controlDate | date : 'dd/MM/yyyy HH:mm' }}</td>
                          <td>{{ cashControlModel.realAmount  | showSignValueType: 'Monetary' }}</td>
                          <td>{{ cashControlModel.calculatedAmount | showSignValueType: 'Monetary' }}</td>
                          <td>{{ (cashControlModel.differenceAmount) | showSignValueType: 'Monetary' }}</td>
                          <td>{{ cashControlModel.salesPersonName }}</td>
                          <td>{{ cashControlModel.description }}</td>
                          <td>
                            @for (action of cashControlModel.actions; track action.name; let last = $last) {
                              <a
                                role="button"
                                (click)="this.triggerAction(action.name,cashControlModel.id)"
                                translate
                              >{{ action.actionName }}{{ last ? "" : " | " }}</a
                              >
                            }
                          </td>
                        </tr>
                      }
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
