import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridOptions,
  GridSizeChangedEvent,
  RowSelectionOptions,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { Observable, combineLatest, debounceTime, filter } from 'rxjs';
import { CommonTranslateService } from 'src/app/common/translate/translate.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BadgeModel } from '../../models/badge-model';
import { FileSizePipe } from '../../pipe/file-size.pipe';
import { filterTrue } from '../../pipe/rxjs/operators';
import { GridService } from '../../service/grid.service';
import { PaginationService } from '../../store/pagination/pagination.service';
import { SearchService } from '../../store/search/search.service';
import {
  ActionType,
  Column,
  navigationObject,
  View,
  ViewType,
} from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { SearchResult } from '../../types';
import { BadgeListComponent } from '../badge/badge-list/badge-list.component';
import { BadgeComponent } from '../badge/badge/badge.component';
import { PhoneNumberComponent } from '../phone-number/phone-number.component';
import { RowSelectionService } from '../../service/commission/row-selection.service';

@Component({
  selector: 'app-data-listing-provider',
  templateUrl: './data-listing-provider.component.html',
  styleUrls: ['./data-listing-provider.component.scss'],
})
@UntilDestroy()
export class DataListingProviderComponent implements OnInit, OnDestroy {
  data$: Observable<SearchResult<any>>;
  view$: Observable<View>;
  columnDefs: ColDef[];
  gridOptions: GridOptions = {};
  rowSelectionOptions: RowSelectionOptions | undefined;
  noRowsTemplate: string;
  viewType$: Observable<ViewType>;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(
    private readonly paginationService: PaginationService,
    private readonly searchService: SearchService,
    private readonly router: Router,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly gridService: GridService,
    private readonly translate: CommonTranslateService,
    private readonly rowSelectionService: RowSelectionService,
  ) {}

  ngOnInit(): void {
    this.viewType$ = this.viewService.getViewType();
    this.view$ = this.viewService.getViews.value$;

    this.noRowsTemplate = this.translate.instant('AG_GRID_NO_ROWS_TO_SHOW');

    //get default grid options from service
    this.gridOptions = this.gridService.getConfig();
    this.rowSelectionOptions = this.gridService.GetRowSelectionConfig();

    this.view$.pipe(filterTrue(), untilDestroyed(this)).subscribe((view) => {
      //Set builtInFilters from view
      const builtinActiveFilters = view.searchView.builtInFilters.filter(
        (filter) => filter.domains.some((domain) => domain.isActive),
      );
      for (const filter of builtinActiveFilters) {
        this.searchService.addDefaultActiveFilter(filter, false);
      }

      //Set permanentFilters from view
      const permanentActiveFilters = view.searchView.permanentFilters?.filter(
        (filter) => filter.domains.some((domain) => domain.isActive),
      );
      if (permanentActiveFilters) {
        for (const filter of permanentActiveFilters) {
          this.searchService.addDefaultActiveFilter(filter, false);
        }
      }

      //Set breadcrumb
      this.breadcrumbService.set('@list', view.title);

      //Set Default view
      let viewType = view.views.split(' ')[0] as ViewType;
      this.viewService.setViewType(viewType);

      //Setup grid columns
      this.columnDefs = [];
      view.treeView.columns.forEach((column) => {
        this.columnDefs.push(this.getColumnDef(column));
      });
      if (view.treeView.defaultSort) {
        let indexDefaultSort = this.columnDefs.findIndex(
          (column) => column.field === view.treeView.defaultSort,
        );
        if (indexDefaultSort >= 0) {
          // this.columnDefs[indexDefaultSort].sort = view.list.orderBy;
          switch (view.treeView.orderBy) {
            case 'asc':
              this.columnDefs[indexDefaultSort].sort = 'asc';
              break;
            case 'desc':
              this.columnDefs[indexDefaultSort].sort = 'desc';
              break;
            default:
              this.columnDefs[indexDefaultSort].sort = null;
              break;
          }
        }
      }
      this.gridOptions.columnDefs = this.columnDefs;

      //Set grid actions
      let navigationObject: navigationObject = {
        left: undefined,
        middle: undefined,
      };
      view.treeView.actions.forEach((action) => {
        if (action.mode === 'Navigate') {
          let type: ActionType = '';
          let url: string = '';
          let idProperty: string = action.idProperty;
          switch (action.actionName) {
            case 'openDetail':
              type = 'openDetail';
              url = [
                this.router.url.substring(0, this.router.url.lastIndexOf('/')),
                'Details',
              ].join('/');
              break;
            case 'openBlankDetail':
              type = 'openBlankDetail';
              url = [
                this.router.url.substring(0, this.router.url.lastIndexOf('/')),
                'Details',
              ].join('/');
              break;
            case 'openExternalDetail':
              type = 'openExternal';
              url = action.route;
              break;
            case 'openExternalBlankDetail':
              type = 'openExternalBlank';
              url = action.route;
              break;
            default:
              type = '';
              url = '';
              idProperty = '';
              break;
          }

          if (action.name === 'leftClick') {
            navigationObject.left = {
              type: type,
              url: url,
              idProperty: idProperty,
            };
          }
          if (action.name === 'middleClick') {
            navigationObject.middle = {
              type: type,
              url: url,
              idProperty: idProperty,
            };
          }
        }
        if (action.mode === 'Action') {
          if (action.name === 'rowSelection') {
            this.rowSelectionOptions = {
              mode: 'multiRow',
              isRowSelectable: (rowNode) => true,
              headerCheckbox: true,
              enableClickSelection: true,
              enableSelectionWithoutKeys: true,
            };
          }
        }
      });

      if (navigationObject) {
        this.gridOptions.onCellMouseDown = (event) => {
          //'ag-Grid-ControlsColumn' is the selection checkbox from ag-grid
          if (event.colDef.colId !== 'ag-Grid-ControlsColumn') {
            if (event.event && event.event['button'] === 1) {
              //middle click
              const middle = navigationObject.middle;
              if (middle) {
                const url: string =
                  middle.url +
                  (middle.url[middle.url.length - 1] === '/' ? '' : '/') +
                  event.data[middle.idProperty];

                if (
                  middle.type === 'openDetail' ||
                  middle.type === 'openExternal'
                ) {
                  this.router.navigate([url]);
                }
                if (
                  middle.type === 'openBlankDetail' ||
                  middle.type === 'openExternalBlank'
                ) {
                  this.router.navigate([]).then((result) => {
                    window.open(url, '_blank');
                  });
                }
              }
            } else {
              //leftclick
              const left = navigationObject.left;
              if (left) {
                const url: string =
                  left.url +
                  (left.url[left.url.length - 1] === '/' ? '' : '/') +
                  event.data[left.idProperty];

                if (
                  left.type === 'openDetail' ||
                  left.type === 'openExternal'
                ) {
                  this.router.navigate([url]);
                }
                if (
                  left.type === 'openBlankDetail' ||
                  left.type === 'openExternalBlank'
                ) {
                  this.router.navigate([]).then((result) => {
                    window.open(url, '_blank');
                  });
                }
              }
            }
          }
        };
      }

      //Set the set and order by in pagination service
      this.paginationService.setSortBy(view.treeView.defaultSort);
      this.paginationService.setOrderBy(view.treeView.orderBy);

      if (this.agGrid != undefined) {
        // this.gridOptions.columnDefs = this.columnDefs;
        this.agGrid.api.sizeColumnsToFit();

        //Update the grid Options
        this.agGrid.api.updateGridOptions(this.gridOptions);
      }
    });

    this.data$ = this.searchService.search.value$;
    combineLatest([
      this.viewService.getViews.value$,
      this.searchService.getActiveFilters(),
      this.paginationService.getPageIndex(),
      this.paginationService.getPageSize(),
      this.paginationService.getSortBy(),
      this.paginationService.getOrderBy(),
    ])
      .pipe(
        filter(
          ([view, activeFilter, pageIndex, pageSize, sortBy, orderBy]) =>
            !!view &&
            !!activeFilter &&
            !!pageIndex &&
            !!pageSize &&
            !!sortBy &&
            !!orderBy,
        ),
        debounceTime(200),
        untilDestroyed(this),
      )
      .subscribe(
        ([view, activeFilters, pageIndex, pageSize, sortBy, orderBy]) => {
          let filters = JSON.parse(JSON.stringify(activeFilters));
          this.searchService.search.call({
            filters: activeFilters,
            url: view.searchView.url,
            pageIndex: pageIndex,
            pageSize: pageSize,
            sortBy: sortBy,
            orderBy: orderBy,
          });
        },
      );
    this.data$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((searchResult) => {
        this.paginationService.setTotalCount(searchResult.totalCount);
        this.paginationService.setPageSize(searchResult.pageSize);
        this.paginationService.setTotalPages(searchResult.totalPages);
      });
  }

  ngOnDestroy(): void {
    this.searchService.search.reset();
  }

  onGridReady(params): void {
    this.gridOptions.columnDefs = this.columnDefs;
    params.api.sizeColumnsToFit();
    params.api.updateGridOptions(this.gridOptions);
  }

  onSortChanged(event): void {
    this.searchService.search.reset();
    const sortedColumn = event.api
      .getColumnState()
      .find((col) => Boolean(col.sort));
    if (sortedColumn != undefined) {
      this.paginationService.setSortBy(sortedColumn.colId);
      this.paginationService.setOrderBy(sortedColumn.sort);
    } else {
      this.view$
        .pipe(debounceTime(200), untilDestroyed(this))
        .subscribe((view) => {
          this.paginationService.setSortBy(view.treeView.defaultSort);
          this.paginationService.setOrderBy(view.treeView.orderBy);
        });
    }
  }

  onGridSizeChanged($event: GridSizeChangedEvent<any>) {
    this.agGrid.api.sizeColumnsToFit();
  }

  onSelectionChanged(event: SelectionChangedEvent<any, any>) {
    this.searchService.setSelectedRows({
      data: event.api.getSelectedRows(),
      isAllSelected: false,
    });
  }

  getColumnDef(column: Column): ColDef<any> {
    let colDef: ColDef = {
      field: column.name,
      headerName: column.title,
      sortable: column.sortable,
      suppressMovable: true, //column not movable for all
      sort: null,
      sortingOrder: ['asc', 'desc'],
      resizable: false,
      unSortIcon: true, //Icon to show sort is possible
    };

    if (column.width != 0) {
      colDef.maxWidth = column.width;
    }

    switch (column.type) {
      case 'date':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new Date(data.value).toLocaleString('fr-FR', {
                dateStyle: 'short',
                timeStyle: 'short',
              })
            : '-';
        };
        return colDef;
      case 'dateOnly':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new Date(data.value).toLocaleString('fr-FR', {
                dateStyle: 'short',
              })
            : '-';
        };
        return colDef;
      case 'badges':
        colDef.cellRenderer = BadgeListComponent;
        return colDef;
      case 'badge':
        colDef.cellRenderer = BadgeComponent;
        colDef.cellRendererParams = <BadgeModel>{
          name: column.name,
          color: column.color,
        };
        return colDef;
      case 'checkbox':
        colDef.cellRenderer = (params) => {
          return `<input type='checkbox' ${
            params.value ? 'checked' : ''
          } disabled />`;
        };
        return colDef;
      case 'activeBadge':
        colDef.cellRenderer = BadgeComponent;
        colDef.cellRendererParams = <BadgeModel>{
          name: column.name,
          color: column.color,
        };
        return colDef;
      case 'phoneNumber':
        colDef.cellRenderer = PhoneNumberComponent;
        return colDef;
      case 'number':
        colDef.cellClass = 'ag-right-aligned-cell';
        return colDef;
      case 'currency':
        colDef.cellClass = 'ag-right-aligned-cell';
        colDef.cellRenderer = (data) => {
          if (Number.isNaN(data.value)) {
            return data.value;
          }

          return data.value.toFixed(2).toString() + ' €';
        };
        return colDef;
      case 'percent':
        colDef.cellClass = 'ag-right-aligned-cell';
        colDef.cellRenderer = (data) => {
          if (Number.isNaN(data.value)) {
            return data.value;
          }

          return data.value.toFixed(2).toString() + ' %';
        };
        return colDef;
      case 'fileSize':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new FileSizePipe().transform(data.value)
            : data.value;
        };
        return colDef;
      case 'array':
        colDef.cellRenderer = (data) => {
          if (!Array.isArray(data.value)) return null;

          return data.value.join(', ');
        };
        return colDef;
      default:
        colDef.cellRenderer = null;
        return colDef;
    }
  }
}
