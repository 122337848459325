import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { Observable, combineLatest, debounceTime, filter } from 'rxjs';
import { BadgeModel } from '../../models/badge-model';
import { FileSizePipe } from '../../pipe/file-size.pipe';
import { debugStop, filterTrue } from '../../pipe/rxjs/operators';
import { PaginationService } from '../../store/pagination/pagination.service';
import { SearchService } from '../../store/search/search.service';
import { Column, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { SearchResult } from '../../types';
import { BadgeListComponent } from '../badge/badge-list/badge-list.component';
import { BadgeComponent } from '../badge/badge/badge.component';
import { PhoneNumberComponent } from '../phone-number/phone-number.component';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss'],
})
@UntilDestroy()
export class GenericGridComponent implements OnInit, OnDestroy, AfterViewInit {
  data$: Observable<SearchResult<any>>;
  view$: Observable<View>;
  columnDefs: ColDef[];
  config: GridOptions = {};

  @Input() detail: boolean;
  @Input() isUniqueId: boolean;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  noRowsTemplate: string;

  constructor(
    private readonly paginationService: PaginationService,
    private readonly searchService: SearchService,
    private readonly router: Router,
    private readonly viewService: ViewService,
    private readonly translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.view$.pipe(filterTrue(), untilDestroyed(this)).subscribe((view) => {
      this.columnDefs = [];
      view.treeView.columns.forEach((column) => {
        this.columnDefs.push(getColumnDef(column));
      });

      if (view.treeView.defaultSort) {
        let indexDefaultSort = this.columnDefs.findIndex(
          (column) => column.field === view.treeView.defaultSort,
        );
        if (indexDefaultSort >= 0) {
          // this.columnDefs[indexDefaultSort].sort = view.list.orderBy;
          switch (view.treeView.orderBy) {
            case 'asc':
              this.columnDefs[indexDefaultSort].sort = 'asc';
              break;
            case 'desc':
              this.columnDefs[indexDefaultSort].sort = 'desc';
              break;
            default:
              this.columnDefs[indexDefaultSort].sort = null;
              break;
          }
        }
      }

      this.paginationService.setSortBy(view.treeView.defaultSort);
      this.paginationService.setOrderBy(view.treeView.orderBy);

      // this.agGrid.api.setColumnDefs(this.columnDefs); //deprecated. Need update if needed
      this.agGrid.api.sizeColumnsToFit();
    });
  }

  ngOnInit(): void {
    this.translate
      .get(['AG_GRID_NO_ROWS_TO_SHOW'])
      .subscribe((translations) => {
        this.noRowsTemplate = translations['AG_GRID_NO_ROWS_TO_SHOW'];
      });

    this.view$ = this.viewService.getViews.value$;
    this.columnDefs = [];

    this.config.rowSelection = 'single';
    this.config.suppressCellFocus = true;
    this.config.headerHeight = 37.2;
    if (this.detail && this.isUniqueId) {
      this.config.onRowClicked = (event) =>
        this.router.navigate([
          this.router.url.substring(0, this.router.url.lastIndexOf('/')),
          'Details',
          event.data.uniqueId,
        ]);
      this.config.onCellMouseDown = (event) => {
        let url = [
          this.router.url.substring(0, this.router.url.lastIndexOf('/')),
          'Details',
          event.data.uniqueId,
        ].join('/');
        if (event.event && event.event['button'] === 1) {
          this.router.navigate([]).then((result) => {
            window.open(url, '_blank');
          });
        }
      };
    }
    if (this.detail && !this.isUniqueId) {
      this.config.onRowClicked = (event) =>
        this.router.navigate([
          this.router.url.substring(0, this.router.url.lastIndexOf('/')),
          'Details',
          event.data.id,
        ]);
      this.config.onCellMouseDown = (event) => {
        let url = [
          this.router.url.substring(0, this.router.url.lastIndexOf('/')),
          'Details',
          event.data.id,
        ].join('/');
        if (event.event && event.event['button'] === 1) {
          this.router.navigate([]).then((result) => {
            window.open(url, '_blank');
          });
        }
      };
    }

    this.viewService.getViews.value$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((view) => {
        // this.searchService.resetActiveFilter();
        const builtinActiveFilters = view.searchView.builtInFilters.filter(
          (filter) => filter.domains.some((domain) => domain.isActive),
        );
        for (const filter of builtinActiveFilters) {
          this.searchService.addDefaultActiveFilter(filter, false);
        }

        //Set permanentFilters from view
        const permanentActiveFilters = view.searchView.permanentFilters?.filter(
          (filter) => filter.domains.some((domain) => domain.isActive),
        );
        if (permanentActiveFilters) {
          for (const filter of permanentActiveFilters) {
            this.searchService.addDefaultActiveFilter(filter, false);
          }
        }
      });

    this.data$ = this.searchService.search.value$;
    combineLatest([
      this.viewService.getViews.value$,
      this.searchService.getActiveFilters(),
      this.paginationService.getPageIndex(),
      this.paginationService.getPageSize(),
      this.paginationService.getSortBy(),
      this.paginationService.getOrderBy(),
    ])
      .pipe(
        filter(
          ([view, activeFilter, pageIndex, pageSize, sortBy, orderBy]) =>
            !!view &&
            !!activeFilter &&
            !!pageIndex &&
            !!pageSize &&
            !!sortBy &&
            !!orderBy,
        ),
        debounceTime(200),
        untilDestroyed(this),
      )
      .subscribe(
        ([view, activeFilters, pageIndex, pageSize, sortBy, orderBy]) => {
          let filters = JSON.parse(JSON.stringify(activeFilters));
          this.searchService.search.call({
            filters: activeFilters,
            url: view.searchView.url,
            pageIndex: pageIndex,
            pageSize: pageSize,
            sortBy: sortBy,
            orderBy: orderBy,
          });
        },
      );
    this.data$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((searchResult) => {
        this.paginationService.setTotalCount(searchResult.totalCount);
        this.paginationService.setPageSize(searchResult.pageSize);
        this.paginationService.setTotalPages(searchResult.totalPages);
      });
  }

  ngOnDestroy(): void {
    this.searchService.search.reset();
  }

  onGridReady(params): void {
    params.api.sizeColumnsToFit();
  }

  onSortChanged(event): void {
    this.searchService.search.reset();
    const sortedColumn = event.columnApi
      .getColumnState()
      .find((col) => Boolean(col.sort));
    if (sortedColumn != undefined) {
      this.paginationService.setSortBy(sortedColumn.colId);
      this.paginationService.setOrderBy(sortedColumn.sort);
    } else {
      this.view$
        .pipe(debounceTime(200), untilDestroyed(this))
        .subscribe((view) => {
          this.paginationService.setSortBy(view.treeView.defaultSort);
          this.paginationService.setOrderBy(view.treeView.orderBy);
        });
    }
  }

  formatUrlDetail(listUrl: string) {
    return listUrl.split('/');
  }

  onGridSizeChanged($event: GridSizeChangedEvent<any>) {
    this.agGrid.api.sizeColumnsToFit();
  }
}

function getColumnDef(column: Column): ColDef<any> {
  let colDef: ColDef = {
    field: column.name,
    headerName: column.title,
    sortable: column.sortable,
    suppressMovable: true, //column not movable for all
    sort: null,
    sortingOrder: ['asc', 'desc'],
    resizable: false,
    unSortIcon: true, //Icon to show sort is possible
  };

  if (column.width != 0) {
    colDef.maxWidth = column.width;
  }

  switch (column.type) {
    case 'date':
      colDef.cellRenderer = (data) => {
        return data.value
          ? new Date(data.value).toLocaleString('fr-FR', {
              dateStyle: 'short',
              timeStyle: 'short',
            })
          : '';
      };
      return colDef;
    case 'dateOnly':
      colDef.cellRenderer = (data) => {
        return data.value
          ? new Date(data.value).toLocaleString('fr-FR', {
              dateStyle: 'short',
            })
          : '';
      };
      return colDef;
    case 'badges':
      console.log('izzez');
      colDef.cellRenderer = BadgeListComponent;
      return colDef;
    case 'badge':
      colDef.cellRenderer = BadgeComponent;
      colDef.cellRendererParams = <BadgeModel>{
        name: column.name,
        color: column.color,
      };
      return colDef;
    case 'checkbox':
      colDef.cellRenderer = (params) => {
        return `<input type='checkbox' ${
          params.value ? 'checked' : ''
        } disabled />`;
      };
      return colDef;
    case 'activeBadge':
      colDef.cellRenderer = BadgeComponent;
      colDef.cellRendererParams = <BadgeModel>{
        name: column.name,
        color: column.color,
      };
      return colDef;
    case 'phoneNumber':
      colDef.cellRenderer = PhoneNumberComponent;
      return colDef;
    case 'number':
      colDef.cellClass = 'ag-right-aligned-cell';
      return colDef;
    case 'fileSize':
      colDef.cellRenderer = (data) => {
        return data.value
          ? new FileSizePipe().transform(data.value)
          : data.value;
      };
      // colDef.cellClass = 'ag-right-aligned-cell';
      return colDef;
    case 'array':
      colDef.cellRenderer = (data) => {
        if (!Array.isArray(data.value)) return null;

        return data.value.join(', ');
      };
      return colDef;
    default:
      colDef.cellRenderer = null;
      return colDef;
  }
}
