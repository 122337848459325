import { Injectable, OnInit } from '@angular/core';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { CommissionService } from '../../store/commissions/commission/commission.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchService } from '../../store/search/search.service';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RowSelectionService {
  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly commissionService: CommissionService,
    private readonly searchService: SearchService,
  ) {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'generateCommissionInvoices')
          this.generateCommissionInvoices();
        this.subHeaderService.setTriggerAction('');
      });
  }

  private generateCommissionInvoices() {
    this.searchService
      .getSelectedRows()
      .pipe(take(1))
      .subscribe((selectedRows) => {
        if (selectedRows.data.length == 0) return;
        this.commissionService.add.call({
          commissionPreviewModels: selectedRows.data,
          isAllSelected: selectedRows.isAllSelected,
        });
      });
  }
}
