import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import {
  BaseLeadFormModel,
  CarInsuranceLeadDisasterDetailFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  ContactFormModel,
  HealthInsuranceLeadDetailEditModel,
  HealthInsuranceLeadFormModel,
  HealthLeadFormModel,
  HouseInsuranceLeadFormModel,
  HouseLeadFormModel,
  InsuranceCategory,
  InsuredType,
  LeadFormModel,
  LeadModel,
} from 'src/app/shared/store/lead/types';
import { SalesTeamService } from 'src/app/shared/store/sales-team/sales-team.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { Entity, View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { formatDate } from '../../../../shared/helper/datehelper';
import { FormService } from '../../../../shared/store/form/form.service';
import {
  DataType,
  DropdownStyle,
} from '../../../../shared/store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-lead-create',
  templateUrl: './lead-create.component.html',
  styleUrls: ['./lead-create.component.scss'],
})
export class LeadCreateComponent implements OnInit, OnDestroy {
  leadForm: FormGroup;
  view$: Observable<View>;
  baseLeadFormModel$: Observable<BaseLeadFormModel>;
  active = 1;
  category: number;
  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly salesTeamService: SalesTeamService,
    private readonly leadService: LeadService,
    private readonly subHeaderService: SubHeaderService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    //avoid patchValue with previous store
    this.leadService.getHealthDetail.reset();
    this.leadService.getCarDetail.reset();
    this.leadService.getHouseDetail.reset();
    // this.fragment = this.route.snapshot.fragment!;
    // this.category = this.route.snapshot.params['category'];
    this.category = 0;

    this.view$ = this.viewService.getViews.value$;
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@leadCreate', x.breadcrumbCreate);
      });
    this.baseLeadFormModel$ = this.leadService.getBaseLeadModel.value$;

    this.leadService.getBaseLeadModel.reset();
    this.leadService.getBaseLeadModel.call(InsuranceCategory.Undefined);
    this.baseLeadFormModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.leadForm?.patchValue({
          // category: this.category,
          //category: x.leadFormModel.category,
          productCategoryId: !x.leadFormModel.productCategoryId
            ? undefined
            : x.leadFormModel.productCategoryId,
          salesTeam: x.leadFormModel.salesTeamId,
          salesTeamMember: x.leadFormModel.salesPersonId,
          effectiveDate: formatDate(
            x.leadFormModel.expectedContractStartDate,
            'ToInverseSqlDate',
          ),
        });
      });

    this.leadForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      address: [''],
      address2: [''],
      zipCode: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      city: [undefined, [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      profession: [undefined],
      productCategoryId: [undefined, [Validators.required]],
      salesTeam: [undefined],
      salesTeamMember: [undefined],
      effectiveDate: [
        formatDate(
          new Date(new Date().setDate(new Date().getDate() + 1)),
          'ToInverseSqlDate',
        ),
        [Validators.required],
      ],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'leadCreatePush') this.onSubmit();
        if (x == 'leadList') {
          this.formService.clear();
          this.router.navigate(['Crm/Leads/List']);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit(): void {
    if (this.leadForm.invalid) {
      this.leadForm.markAllAsTouched();
      this.formService.countErrors(this.leadForm, true);
      return;
    }

    let result$: Observable<any> = of();

    let leadFormModel: LeadFormModel = {
      id: undefined,
      category: InsuranceCategory.Health,
      categoryLoc: '',
      firstName: this.leadForm.value.firstname!,
      lastName: this.leadForm.value.lastname!,
      address: this.leadForm.value.address!,
      address2: this.leadForm.value.address2!,
      zipCode: this.leadForm.value.zipCode!,
      city: this.leadForm.value.city!,
      email: this.leadForm.value.email!,
      phoneNumber: this.leadForm.value.phoneNumber!,
      salesTeamId: this.leadForm.value.salesTeam!,
      salesPersonId: this.leadForm.value.salesTeamMember!,
      title: '',
      medium: this.leadForm.value.marketingFormGroup.marketingMedium!,
      source: this.leadForm.value.marketingFormGroup.marketingSource!,
      campaignId: this.leadForm.value.marketingFormGroup.marketingCampaignId!,
      profession: this.leadForm.value.profession!,
      creationDate: new Date(),
      expectedContractStartDate: this.leadForm.value.effectiveDate,
      productCategoryId: this.leadForm.value.productCategoryId,
    };

    let contactFormModel: ContactFormModel = {
      id: undefined,
      firstName: this.leadForm.value.firstname!,
      lastName: this.leadForm.value.lastname!,
      address: this.leadForm.value.address!,
      address2: this.leadForm.value.address2!,
      zipCode: this.leadForm.value.zipCode!,
      city: this.leadForm.value.city!,
      email: this.leadForm.value.email!,
      phoneNumber: this.leadForm.value.phoneNumber!,
      profession: this.leadForm.value.profession!,
    };

    let baseLeadFormModel: BaseLeadFormModel = {
      leadFormModel: leadFormModel,
      contactFormModel: contactFormModel,
    };

    switch (this.category) {
      case InsuranceCategory.Health: {
        leadFormModel.category = InsuranceCategory.Health;
        const subscriber: HealthInsuranceLeadDetailEditModel = {
          socialSecurityKey:
            this.leadForm.value.healthFormGroup.subscriberRegime,
          birthDate: this.leadForm.value.healthFormGroup.subscriberBirthDate,
          insuredType: InsuredType.Holder,
          firstName: this.leadForm.value.healthFormGroup.subscriberFirstName,
          lastName: this.leadForm.value.healthFormGroup.subscriberLastName,
        };

        let partners: HealthInsuranceLeadDetailEditModel[] = [];

        this.leadForm.value.healthFormGroup.partners.forEach((x) => {
          const partner: HealthInsuranceLeadDetailEditModel = {
            socialSecurityKey: x.partnerRegime,
            birthDate: x.partnerBirthDate,
            insuredType: InsuredType.Partner,
            firstName: x.partnerFirstName,
            lastName: x.partnerLastName,
          };
          partners.push(partner);
        });

        let children: HealthInsuranceLeadDetailEditModel[] = [];

        this.leadForm.value.healthFormGroup.children.forEach((x) => {
          const child: HealthInsuranceLeadDetailEditModel = {
            socialSecurityKey: x.childRegime,
            birthDate: x.childBirthDate,
            insuredType: InsuredType.Child,
            firstName: x.childFirstName,
            lastName: x.childLastName,
          };
          children.push(child);
        });

        let insureds: HealthInsuranceLeadDetailEditModel[];

        insureds = [subscriber, ...partners, ...children];

        let healthInsuranceLeadFormModel: HealthInsuranceLeadFormModel = {
          routineCareCoverageLevel:
            this.leadForm.value.healthFormGroup.routineCare,
          dentalCoverageLevel: this.leadForm.value.healthFormGroup.dental,
          hospitalizationCoverageLevel:
            this.leadForm.value.healthFormGroup.hospi,
          opticalCoverageLevel: this.leadForm.value.healthFormGroup.optical,
          county: '',
        };

        let healthLeadFormModel: HealthLeadFormModel = {
          baseLeadFormModel: baseLeadFormModel,
          healthInsuranceLeadFormModel: healthInsuranceLeadFormModel,
          insureds: insureds,
        };

        console.log(healthLeadFormModel);

        result$ = this.leadService.createHealthLead.call(healthLeadFormModel);
        break;
      }
      case InsuranceCategory.Car: {
        leadFormModel.category = InsuranceCategory.Car;

        let carInsuranceLeadModel: CarInsuranceLeadModel = {
          driverFirstName: this.leadForm.value.driverFormGroup.driverFirstname,
          driverLastName: this.leadForm.value.driverFormGroup.driverLastname,
          driverBirthdate: this.leadForm.value.driverFormGroup.driverBirthdate,
          driverZipCode: this.leadForm.value.driverFormGroup.driverZipCode,
          driverCity: this.leadForm.value.driverFormGroup.driverCity,
          driverAddress: this.leadForm.value.driverFormGroup.driverAddress,
          driverMaritalStatus:
            this.leadForm.value.driverFormGroup.driverMaritalStatus,
          licenseDate: this.leadForm.value.driverFormGroup.driverLicenseDate,
          isDriverVehiculeRegistrationHolder:
            this.leadForm.value.driverFormGroup.driverVehicleRegistrationHolder,
          vehiculeRegistrationHolder:
            this.leadForm.value.vehicleFormGroup.vehicleRegistrationHolder,
          vehiculeRegistrationHolderNameLastName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderCompanyName,
          vehiculeRegistrationHolderNameFirstName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameLastName,
          vehiculeRegistrationHolderCompanyName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameFirstName,
          vehiculeRegistrationHolderBirthdate:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderBirthdate,
          yearsInsured: this.leadForm.value.driverFormGroup.driverYearsInsured,
          bonusMalus: this.leadForm.value.driverFormGroup.driverBonusMalus,
          monthInsuranceInterruption:
            this.leadForm.value.driverFormGroup
              .driverMonthInsuranceInterruption,
          insuranceTermination:
            this.leadForm.value.driverFormGroup.driverInsuranceTermination,
          licenseAnnulation:
            this.leadForm.value.driverFormGroup.driverLicenseAnnulation,
          noLicenseConviction:
            this.leadForm.value.driverFormGroup.driverNoLicenseConviction,
          licenseCancel:
            this.leadForm.value.driverFormGroup.driverLicenseCancel,
          licenseCancelMotive:
            this.leadForm.value.driverFormGroup.driverLicenseCancelMotive,
          licenseCancelDate:
            this.leadForm.value.driverFormGroup.driverLicenseCancelDate,
          licenseSuspension:
            this.leadForm.value.driverFormGroup.driverLicenseSuspension,
          licenseSuspensionMotive:
            this.leadForm.value.driverFormGroup.driverLicenseSuspensionMotive,
          licenseSuspensionDate:
            this.leadForm.value.driverFormGroup.driverLicenseSuspensionDate,
          longestSuspension:
            this.leadForm.value.driverFormGroup.driverLongestSuspension,
          alcoholDrugControl:
            this.leadForm.value.driverFormGroup.driverAlcoholDrugControl,
          nbCarDisaster: '',
          licensePlateNumber:
            this.leadForm.value.vehicleFormGroup.vehicleLicensePlateNumber,
          brand: this.leadForm.value.vehicleFormGroup.vehicleBrand,
          model: this.leadForm.value.vehicleFormGroup.vehicleModel,
          exactModel: this.leadForm.value.vehicleFormGroup.vehicleExactModel,
          carBody: this.leadForm.value.vehicleFormGroup.vehicleCarBody,
          carEnergie: this.leadForm.value.vehicleFormGroup.vehicleCarEnergy,
          carPower: this.leadForm.value.vehicleFormGroup.vehicleCarPower,
          vehiculeUsage: this.leadForm.value.vehicleFormGroup.vehicleUsage,
          vehiculeUsageProSupp:
            this.leadForm.value.vehicleFormGroup.vehicleUsageProSupp,
          parkingType: this.leadForm.value.vehicleFormGroup.vehicleParkingType,
          dateOfCirculation:
            this.leadForm.value.vehicleFormGroup.vehicleDateOfCirculation,
          contractStartDate: this.leadForm.value.effectiveDate,
          insuranceCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceInsuranceCoverage,
          currentInsuranceCompany:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceCurrentInsuranceCompany,
          driverBodyCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceDriverBodyCoverage,
          replacementVehicleCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceReplacementVehicleCoverage,
          driverPropertyCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceDriverPropertyCoverage,
          brandNewCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceBrandNewCoverage,
          moneyLossCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceMoneyLossCoverage,
          carDisasters: [],
          id: 0,
          insuranceLeadLeadId: 0,
          parkingTypeLoc: '',
          driverGender: this.leadForm.value.driverFormGroup.driverGender,
          vehiculeUsageLoc: '',
          vehiculeUsageProSuppLoc: '',
          driverMaritalStatusLoc: '',
        };

        let disasters: CarInsuranceLeadDisasterDetailFormModel[] = [];

        this.leadForm.value.driverFormGroup.driverDisasters.forEach((x) => {
          const disaster: CarInsuranceLeadDisasterDetailFormModel = {
            carDisasterDate: x.carDisasterDate,
            carDisasterReason: x.carDisasterReason,
            carDisasterResponsiblePerson: x.carDisasterResponsiblePerson,
          };
          disasters.push(disaster);
        });

        const carLeadFormModel: CarLeadFormModel = {
          baseLeadFormModel: baseLeadFormModel,
          carInsuranceLeadModel: carInsuranceLeadModel,
          disasters: disasters,
        };

        result$ = this.leadService.createCarLead.call(carLeadFormModel);

        break;
      }
      case InsuranceCategory.House: {
        leadFormModel.category = InsuranceCategory.House;

        const houseInsuranceLeadFormModel: HouseInsuranceLeadFormModel = {
          address: this.leadForm.value.houseFormGroup.houseAddress,
          zipCode: this.leadForm.value.houseFormGroup.houseZipCode,
          city: this.leadForm.value.houseFormGroup.houseCity,
          maritalStatus: this.leadForm.value.houseFormGroup.houseAddress,
          profession: this.leadForm.value.houseFormGroup.houseAddress,
          housingType: this.leadForm.value.houseFormGroup.houseHousingType,
          occupancyRole: this.leadForm.value.houseFormGroup.houseOccupancyRole,
          nbOfRooms: this.leadForm.value.houseFormGroup.houseNbOfRooms,
          area: this.leadForm.value.houseFormGroup.houseArea,
          constructionType:
            this.leadForm.value.houseFormGroup.houseConstructionType,
          roofType: this.leadForm.value.houseFormGroup.houseRoofType,
          amountToInsure:
            this.leadForm.value.houseFormGroup.houseAmountToInsure,
          flatLevel: this.leadForm.value.houseFormGroup.houseFlatLevel,
          residencyType: this.leadForm.value.houseFormGroup.houseResidencyType,
          fireCoverage: this.leadForm.value.houseFormGroup.houseFireCoverage,
          waterCoverage: this.leadForm.value.houseFormGroup.houseWaterCoverage,
          theftCoverage: this.leadForm.value.houseFormGroup.houseTheftCoverage,
          glassBreakCoverage:
            this.leadForm.value.houseFormGroup.houseGlassBreakCoverage,
          publicLiabilityCoverage:
            this.leadForm.value.houseFormGroup.housePublicLiabilityCoverage,
          feeCancellationCoverage:
            this.leadForm.value.houseFormGroup.houseFeeCancellationCoverage,
          electricalDamageCoverage:
            this.leadForm.value.houseFormGroup.houseElectricalDamageCoverage,
          poolCoverage: this.leadForm.value.houseFormGroup.housePoolCoverage,
          dogCoverage: this.leadForm.value.houseFormGroup.houseDogCoverage,
          currentInsuranceCompany:
            this.leadForm.value.houseFormGroup.houseCurrentInsuranceCompany,
        };

        const houseLeadFormModel: HouseLeadFormModel = {
          baseLeadFormModel: baseLeadFormModel,
          houseInsuranceLeadFormModel: houseInsuranceLeadFormModel,
        };

        result$ = this.leadService.createHouseLead.call(houseLeadFormModel);

        break;
      }
      case InsuranceCategory.Undefined: {
        leadFormModel.category = InsuranceCategory.Undefined;

        result$ = this.leadService.createLead.call(baseLeadFormModel);
        // return;
        break;
      }
      default: {
        break;
      }
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x: LeadModel) => {
        this.formService.clear();
        this.router.navigate(['Crm/Leads/Details', x.uniqueId.toString()]);
      });
  }

  ngOnDestroy() {
    this.leadService.createHouseLead.reset();
    this.leadService.createCarLead.reset();
    this.leadService.createHealthLead.reset();
  }

  onBlurLastName($event: FocusEvent) {
    if (
      !this.leadForm.controls['healthFormGroup'] ||
      !this.leadForm.controls['healthFormGroup'].get('subscriberLastName') ||
      this.leadForm.controls['healthFormGroup'].get('subscriberLastName') ===
        null
    )
      return;
    else {
      // if (
      //   this.leadForm.controls['healthFormGroup'].get('subscriberLastName')!
      //     .value === ''
      // ) {
      let formControlValue =
        this.leadForm.controls['healthFormGroup'].get('subscriberLastName');
      formControlValue?.setValue(this.leadForm.get('lastname')?.value);
      // }
    }
  }

  onBlurFirstName($event: FocusEvent) {
    if (
      !this.leadForm.controls['healthFormGroup'] ||
      !this.leadForm.controls['healthFormGroup'].get('subscriberFirstName') ||
      this.leadForm.controls['healthFormGroup'].get('subscriberFirstName') ===
        null
    )
      return;
    else {
      // if (
      //   this.leadForm.controls['healthFormGroup'].get(
      //     'subscriberFirstName',
      //   )!.value === ''
      // ) {
      let formControlValue = this.leadForm.controls['healthFormGroup'].get(
        'subscriberFirstName',
      );
      formControlValue?.setValue(this.leadForm.get('firstname')?.value);
      // }
    }
  }

  onCategoryChange(category: number) {
    if (this.category !== category) {
      this.category = category;
    }
  }

  protected readonly DataType = DataType;
}
