<app-list-subheader-empty></app-list-subheader-empty>
<div *ngIf="companyCommissions$ | async as companyCommissions">
  <table class="table table-striped border-bottom">
    <thead>
      <tr>
        <th></th>
        <th>Mutuelle Santé</th>
        <th>Assurance Auto</th>
        <th>Assurance Habitation</th>
        <th>Montant total</th>
      </tr>
    </thead>
    <tbody>
      @for(company of companyCommissions; track company.totalAmount) {

      <tr>
        <td>{{ company.companyName }}</td>
        <td>{{ company.healthAmount }} €</td>
        <td>{{ company.carAmount }} €</td>
        <td>{{ company.houseAmount }} €</td>
        <td>{{ company.totalAmount }} €</td>
      </tr>
      <div></div>
      }
    </tbody>
  </table>
</div>
