import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CommissionService } from 'src/app/shared/store/commissions/commission/commission.service';
import { CompanyCommissionModel } from 'src/app/shared/store/commissions/commission/types';

@UntilDestroy()
@Component({
  selector: 'app-company-commission',
  templateUrl: './company-commission.component.html',
  styleUrl: './company-commission.component.scss',
})
export class CompanyCommissionComponent implements OnInit, OnDestroy {
  companyCommissions$: Observable<CompanyCommissionModel[]>;

  constructor(private readonly commissionService: CommissionService) {}
  ngOnInit(): void {
    this.companyCommissions$ =
      this.commissionService.getCompanyCommissions.value$;

    this.commissionService.getCompanyCommissions.call({
      startDate: new Date(2024, 12, 1),
      endDate: new Date(2024, 12, 31),
    });
  }
  ngOnDestroy(): void {}
}
