import { CommissionPlanType } from '../commission-plan-rule/types';

export const commissionFeatureKey = 'commission';

export class CommissionModel {
  id: number;
  userId: number;
  contractId: number;
  amount: number;
  commissionPlanRuleId: number;
}

export type CommissionPreviewModel = {
  id: number;
  contractContactName: string;
  name: string;
  accountMoveType: string;
  storesubcriptionDate: string;
  accountMoveStatus: string;
  companyCommissionAmount: number;
  salesPersonCommissionRate: number;
  shareCommissionRate: number;
  salesPersonCommissionAmount: number;
  salesPersonName: string;
};

export type CompanyCommissionModel = {
  InsuranceCompanyId: number;
  companyName;
  healthAmount: number;
  carAmount: number;
  houseAmount: number;
  totalAmount: number;
};
