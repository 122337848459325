import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { navigationActions } from './navigation.actions';
import { navigationSelectors } from './navigation.selectors';
import { Menu, Module, ModuleId } from './types';
import { combineLatest, filter, Observable, tap } from 'rxjs';
import { NotificationType } from '../notification/types';
import { RouterService } from '../router/router.service';
import { OAuthenticationService } from '../../authentication/oauthentication.service';
import { FormService } from '../form/form.service';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class NavigationService extends BaseServiceFacade {
  moduleIdData$: Observable<ModuleId>;
  isFormDirty$: Observable<boolean>;

  constructor(
    store: Store<AppState>,
    private readonly routerService: RouterService,
    private readonly authService: OAuthenticationService,
    private readonly formService: FormService,
    private readonly notificationService: NotificationService,
  ) {
    super(store);

    this.moduleIdData$ = this.routerService.routeModuleIdData$;
    this.isFormDirty$ = this.formService.getIsDirty();

    combineLatest([this.moduleIdData$, this.isFormDirty$])
      .pipe(
        // tap(([moduleId, isDirty]) => {
        //   console.log(moduleId);
        // }),
        filter(([moduleId, isDirty]) => !isDirty),
      )
      .subscribe(([moduleId, isDirty]) => {
        if (this.authService.isAuthenticated()) {
          if (moduleId) {
            this.getMenus.call(ModuleId[moduleId]);
          } else {
            this.getMenus.reset();
          }

          this.notificationService.clearByType(
            NotificationType.ToastValidation,
          );
        }
      });
  }
  getModules: GenericApiCall<Module[], void> = this.genericApiCall(
    navigationActions.getModules,
    navigationSelectors.getModules,
    true,
  );
  getMenus: GenericApiCall<Menu[], string> = this.genericApiCall(
    navigationActions.getMenus,
    navigationSelectors.getMenus,
    true,
  );
}
